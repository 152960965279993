export const OT_REVIEW_TASK_TYPE_ID = 3
export const UPLOAD_TASK_TYPE_ID = 4
export const REVIEW_TASK_TYPE_ID = 5

export const UPLOAD_TASK_TYPE_VALUE = 'Upload Task'
export const REVIEW_TASK_TYPE_VALUE = 'Review Task'

export const OT_REVIEW_TASK_QUERY_VALUE = 'ot-review'
export const UPLOAD_TASK_QUERY_VALUE = 'upload'
export const REVIEW_TASK_QUERY_VALUE = 'review'

export const ADMIN_USER_ROLE_NAME = 'admin'
export const REVIEWER_ROLE_NAME = 'reviewer'
export const OBSERVER_ROLE_NAME = 'observer'
export const UPLOADER_ROLE_NAME = 'uploader'

export const MIME_TYPE_PDF = 'application/pdf'

export const TASK_TYPE_PARAM_ID_MAP = {
  [OT_REVIEW_TASK_QUERY_VALUE]: OT_REVIEW_TASK_TYPE_ID,
  [UPLOAD_TASK_QUERY_VALUE]: UPLOAD_TASK_TYPE_ID,
  [REVIEW_TASK_QUERY_VALUE]: REVIEW_TASK_TYPE_ID,
}

export const TASK_TYPE_VALUE_PARAM_MAP = {
  [UPLOAD_TASK_TYPE_VALUE]: UPLOAD_TASK_QUERY_VALUE,
  [REVIEW_TASK_TYPE_VALUE]: REVIEW_TASK_QUERY_VALUE,
}

export const TASK_TYPE_ID_VALUE_MAP = {
  [UPLOAD_TASK_TYPE_ID]: UPLOAD_TASK_TYPE_VALUE,
  [REVIEW_TASK_TYPE_ID]: REVIEW_TASK_TYPE_VALUE,
}

export const WEEKLY_AD_MPR_MERCHANTS_TASK_NAME =
  'MPR (Merchant Partner Review) – Merchants'
export const WEEKLY_AD_MPR_MARKETING_TASK_NAME =
  'MPR (Merchant Partner Review) – Marketing'
export const WEEKLY_AD_IP_TASK_NAME = 'IP (Initial Photography)'
export const WEEKLY_AD_PCR_TASK_NAME = 'PCR (Post Creative Review)'
export const WEEKLY_AD_OPV_PROMOSIGN_TASK_NAME =
  'OPV (Offer & Price Validation) – Promo Signing'
export const WEEKLY_AD_OPV_COPYEDIT_TASK_NAME =
  'OPV (Offer & Price Validation) – Copyediting'
export const WEEKLY_AD_FR_TASK_NAME = 'FR (Final Release)'
export const WEEKLY_AD_ECMPR_MERCHANTS_TASK_NAME =
  'ECMPR (Early Concept Merchant Partner Review)–Merchants'
export const WEEKLY_AD_ECMPR_MARKETING_TASK_NAME =
  'ECMPR (Early Concept Merchant Partner Review)–Marketing'
export const WEEKLY_AD_WBMPR_MERCHANTS_TASK_NAME =
  'WBMPR (White Box Merchant Partner Review)–Merchants'
export const WEEKLY_AD_WBMPR_MARKETING_TASK_NAME =
  'WBMPR (White Box Merchant Partner Review)–Marketing'

export const secondarySortFields = ['mime_type', 'creative_asset_status']

export const UPLOAD_FILE_TARGET_NAME = 'INTERNAL'

export const REPORT_COMPLETE = 'COMPLETE'
export const REPORT_IN_PROCESS = 'IN_PROCESS'
export const REPORT_ERROR = 'ERROR'

export const FULL_FORM_PROJECT_TYPES = [
  'Experiential Marketing',
  'Other',
  'Roundel Final Files',
  'CreativeHub',
]

//Roundel Specific
export const FIELD_PROJECT_ADMINS = 'project_admins'
export const TASK_FIELD_SEQUENCE_START = 21 //includes 21

export const ROUNDEL_CLIENT_FILES_TASK_NAME = 'Upload Client Files'
export const ROUNDEL_CLIENT_FILES_UPLOADERS = 'client_files_uploaders'
export const ROUNDEL_CLIENT_FILES_DUE_DATE = 'client_files_due_date'

export const ROUNDEL_FINAL_FILES_TASK_NAME = 'Upload Final Files'
export const ROUNDEL_FINAL_FILES_UPLOADERS = 'final_files_uploaders'
export const ROUNDEL_FINAL_FILES_DUE_DATE = 'final_files_due_date'

export const EDIT_PROJECT_KEYS_TO_REMOVE = [
  ROUNDEL_CLIENT_FILES_UPLOADERS,
  ROUNDEL_CLIENT_FILES_DUE_DATE,
  ROUNDEL_FINAL_FILES_UPLOADERS,
  ROUNDEL_FINAL_FILES_DUE_DATE,
]
