import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import moment from 'moment-timezone'
import { withStyles } from '@mui/styles'
import { Button, Grid, IconButton } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { Formik, Field } from 'formik'
import {
  BrandsFormField,
  ConfidentialFormField,
  DateFormField,
  InfluencerFormField,
  PeopleFormField,
  SelectFormField,
  TextFormField,
} from '../../FormikFormFields'
import {
  formatInitialValuesOnProjectType,
  generateBaseIntialValues,
  validateIsRequired,
} from '../../../helpers/ProjectFormHelpers'
import { TASK_FIELD_SEQUENCE_START } from '../../../constants/projectTask'

const styles = {
  confidentialFields: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  releaseDate: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 20px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    bottom: '-24px',
    width: '100%',
    backgroundColor: 'white',
    padding: '10px 0 10px 10px',
    marginTop: '10px',
    borderTop: '1px solid #6f7b6f57',
  },
  fullWidthField: {
    display: 'flex',
    width: '100%',
    padding: '0px 20px',
  },
  createForm: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  createFormFields: {
    width: '47%',
    padding: '0px 20px',
  },
  taskHeading: {
    paddingTop: '10px',
    marginLeft: '20px',
    color: 'black',
    borderTop: '1px solid #6f7b6f57',
    width: '95%',
  },
}

export const NewProjectForm = ({
  onSubmit = {},
  formData = {},
  projectTypeDictionary = {},
  resetForm = () => {},
  projectInitiator = '',
  classes = {},
}) => {
  const { projectType = '', projectId = '', metadataList = [] } = formData
  const projectIdType = get(projectTypeDictionary, projectType, 'text')
  const projectIdInitialValue =
    projectIdType === 'text' ? projectId : moment(projectId)

  // generateBaseIntialValues: creates initial values based on value type
  const genericInitialValues = generateBaseIntialValues({
    metadataList,
    projectIdType,
    projectIdInitialValue,
    projectInitiator,
    projectType,
  })

  // formatInitialValuesOnProjectType: Handles all project type specific customization
  const formattedInitialValues = formatInitialValuesOnProjectType(
    projectType,
    genericInitialValues,
  )

  return (
    <Formik
      initialValues={{
        ...formattedInitialValues,
        projectType,
        confidential: false,
        project_release_date: null,
        project_code: '',
      }}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const { handleSubmit = () => {}, values = {} } = formikProps
        const projectFieldList =
          (metadataList || []).filter(
            (metadataFields) =>
              metadataFields.fieldSequence < TASK_FIELD_SEQUENCE_START,
          ) || []
        const taskFieldList =
          (metadataList || []).filter(
            (metadataFields) =>
              metadataFields.fieldSequence >= TASK_FIELD_SEQUENCE_START,
          ) || []
        return (
          <div>
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid container item className={classes.createForm}>
                  <Grid item className={classes.createFormFields}>
                    <Field
                      name="projectType"
                      label="Project Type"
                      disabled
                      component={TextFormField}
                    />
                  </Grid>
                  <Grid item className={classes.createFormFields}>
                    <Field
                      data-cy="isConfidential"
                      name="confidential"
                      component={ConfidentialFormField}
                    />
                  </Grid>
                  {values.confidential && (
                    <React.Fragment>
                      <div className={classes.confidentialFields}>
                        <Grid item className={classes.createFormFields}>
                          <Field
                            data-cy="projectCodeName"
                            iconLabel={'Label'}
                            name={'project_code'}
                            label={'Project Code Name'}
                            component={TextFormField}
                          />
                        </Grid>
                        <span className={classes.releaseDate}>
                          <Field
                            iconLabel={'Date'}
                            name={'project_release_date'}
                            label={'Internal Release Date'}
                            component={DateFormField}
                            data-cy="create_project_release_date"
                          />
                          <div>
                            <IconButton
                              style={{ height: '32px', width: '32px' }}
                              variant="contained"
                              onClick={() =>
                                formikProps.setFieldValue(
                                  'project_release_date',
                                  null,
                                )
                              }
                              size="large"
                            >
                              <Clear />
                            </IconButton>
                          </div>
                        </span>
                      </div>
                    </React.Fragment>
                  )}
                  {projectFieldList.map((field = {}) => {
                    switch (field.metadataType || '') {
                      case 'autocomplete': {
                        let menuItems = [...field.values]
                        if (projectType === 'Beauty Boxes') {
                          menuItems.push({
                            text: 'Beauty Boxes',
                            value: 'Beauty Boxes',
                          })
                        }
                        return (
                          <Grid item className={classes.createFormFields}>
                            <Field
                              data-cy={`create_${field.metadataName}`}
                              key={field.metadataName}
                              iconLabel={field.iconDescriptor}
                              name={field.metadataName}
                              label={field.displayText}
                              component={SelectFormField}
                              menuItems={menuItems}
                              required={field.isRequired}
                              validate={validateIsRequired(field.isRequired)}
                            />
                          </Grid>
                        )
                      }
                      case 'contact':
                        return (
                          <Grid item className={classes.createFormFields}>
                            <Field
                              data-cy={`create_${field.metadataName}`}
                              key={field.metadataName}
                              iconLabel={field.iconDescriptor}
                              name={field.metadataName}
                              label={field.displayText}
                              component={PeopleFormField}
                              required={field.isRequired}
                              validate={validateIsRequired(
                                field.isRequired,
                                field.displayText,
                              )}
                            />
                          </Grid>
                        )
                      case 'date':
                        return (
                          <Grid item className={classes.createFormFields}>
                            <Field
                              data-cy={`create_${field.metadataName}`}
                              key={field.metadataName}
                              iconLabel={field.iconDescriptor}
                              name={field.metadataName}
                              label={field.displayText}
                              component={DateFormField}
                              required={field.isRequired}
                              validate={validateIsRequired(
                                field.isRequired,
                                field.displayText,
                              )}
                            />
                          </Grid>
                        )
                      default:
                        // For Roundel Projects, project_initiator field is not needed
                        if (field.metadataName === 'project_initiator') {
                          return (
                            <>
                              {projectType === 'Roundel Final Files' ? (
                                ''
                              ) : (
                                <Grid item className={classes.createFormFields}>
                                  <Field
                                    disabled
                                    key={field.metadataName}
                                    iconLabel={field.iconDescriptor}
                                    name={field.metadataName}
                                    label={field.displayText}
                                    component={TextFormField}
                                    data-cy={`create_${field.metadataName}`}
                                  />
                                </Grid>
                              )}
                            </>
                          )
                        }

                        if (field.metadataName === 'brand') {
                          return (
                            <Grid item className={classes.createFormFields}>
                              <Field
                                key={field.metadataName}
                                iconLabel={field.iconDescriptor}
                                name={field.metadataName}
                                label={field.displayText}
                                component={BrandsFormField}
                                data-cy={`create_${field.metadataName}`}
                                />
                            </Grid>
                          )
                        }

                        if (
                          projectType === 'Influencer Marketing' &&
                          field.metadataName === 'project_name'
                        ) {
                          return (
                            <Grid item className={classes.createFormFields}>
                              <Field
                                data-cy={`create_${field.metadataName}`}
                                key={field.metadataName}
                                iconLabel={field.iconDescriptor}
                                name={field.metadataName}
                                label={field.displayText}
                                component={InfluencerFormField}
                                required={field.isRequired}
                                validate={validateIsRequired(
                                  field.isRequired,
                                  field.displayText,
                                )}
                              />
                            </Grid>
                          )
                        }

                        return (
                          <Grid item className={classes.createFormFields}>
                            <Field
                              data-cy={`create_${field.metadataName}`}
                              key={field.metadataName}
                              iconLabel={field.iconDescriptor}
                              name={field.metadataName}
                              label={field.displayText}
                              component={TextFormField}
                              required={field.isRequired}
                              validate={validateIsRequired(
                                field.isRequired,
                                field.displayText,
                              )}
                            />
                          </Grid>
                        )
                    }
                  })}
                </Grid>
                {taskFieldList?.length ? (
                  <Grid container item className={classes.taskHeading}>
                    Tasks
                  </Grid>
                ) : (
                  ''
                )}
                <Grid container item>
                  {taskFieldList.map((field = {}) => {
                    switch (field.metadataType || '') {
                      case 'contact':
                        return (
                          <Grid item className={classes.createFormFields}>
                            <Field
                              data-cy={`create_${field.metadataName}`}
                              key={field.metadataName}
                              iconLabel={field.iconDescriptor}
                              name={field.metadataName}
                              label={field.displayText}
                              component={PeopleFormField}
                              required={field.isRequired}
                              validate={validateIsRequired(
                                field.isRequired,
                                field.displayText,
                              )}
                            />
                          </Grid>
                        )
                      case 'date':
                        return (
                          <Grid item className={classes.createFormFields}>
                            <Field
                              data-cy={`create_${field.metadataName}`}
                              key={field.metadataName}
                              iconLabel={field.iconDescriptor}
                              name={field.metadataName}
                              label={field.displayText}
                              component={DateFormField}
                              required={field.isRequired}
                              validate={validateIsRequired(
                                field.isRequired,
                                field.displayText,
                              )}
                            />
                          </Grid>
                        )
                      default:
                        return (
                          <Grid item className={classes.createFormFields}>
                            <Field
                              data-cy={`create_${field.metadataName}`}
                              key={field.metadataName}
                              iconLabel={field.iconDescriptor}
                              name={field.metadataName}
                              label={field.displayText}
                              component={TextFormField}
                              required={field.isRequired}
                              validate={validateIsRequired(
                                field.isRequired,
                                field.displayText,
                              )}
                            />
                          </Grid>
                        )
                    }
                  })}
                </Grid>
              </Grid>
            </form>
            <div>*Required Fields</div>
            <div className={classes.footer}>
              <Button
                data-cy="projectStartOverButton"
                variant="contained"
                onClick={resetForm}
              >
                Start Over
              </Button>
              <Button
                data-cy="projectCreateButton"
                onClick={handleSubmit}
                variant="contained"
                color="secondary"
              >
                Create
              </Button>
            </div>
          </div>
        )
      }}
    </Formik>
  )
}

NewProjectForm.propTypes = {
  onSubmit: PropTypes.func,
  formData: PropTypes.any,
  projectTypeDictionary: PropTypes.object,
  resetForm: PropTypes.func,
  projectInitiator: PropTypes.shape({
    display_name: PropTypes.string,
    login_id: PropTypes.string,
    email_address: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  classes: PropTypes.object,
}

export default withStyles(styles)(NewProjectForm)
