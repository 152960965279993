import React, { useState, useEffect } from 'react'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import Markdown from 'markdown-to-jsx'
import { reviewTestAssets } from '../services/genaiService'

const useStyles = makeStyles({
  lightLabelClass: {
    color: '#666666',
    fontWeight: 500,
    fontSize: '14px',
    paddingBottom: '5px',
  },
  modelSettingClass: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modelSettingItemClass: {
    width: '25%'
  },
  promptInfoClass: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    padding: '15px',
  },
  promptInfoValueClass: {
    width: '18vw',
    wordBreak: 'break-word',
    maxHeight: '150px',
    overflow: 'scroll',
  },
  promptInfoBulkValue: {
    wordBreak: 'break-word',
    maxHeight: '300px',
    overflow: 'scroll',
  },
  bulkResponseClass: {
    borderTop: '1px solid #D6D6D6',
    paddingTop: '10px',
  },
  reviewFieldsContainer: {
    borderTop: '1px solid #D6D6D6',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '15px',
  },
  submitButton: {
    backgroundColor: '#178295',
    color: 'white',
    '&:disabled': {
      color: 'grey',
      backgroundColor: '#7f889994',
    },
    '&:hover': {
      backgroundColor: '#178295',
      color: 'white',
    },
  },
})

function BulkAssetReviewForm({
  bulkTestReviewData = {},
  selectedAssetDetails = {},
  isBulkViewMode = false,
  selectedAssets = [],
  onReviewSuccessCallback = () => { },
}) {
  const classes = useStyles()
  const {
    response = '',
    quality_status = '',
    review_note = '',
  } = selectedAssetDetails
  const [quality, setQuality] = useState(isBulkViewMode ? quality_status : '')
  const [notes, setNotes] = useState(isBulkViewMode ? review_note : '')
  const { displayName = '', isAdGroupSuperAdmin = false, isLibrarian = false } = useSelector((state) => state.auth)

  const onSaveReview = async () => {
    const assetReviewUpdatePayload = {
      asset_test_ids: selectedAssets,
      quality_status: quality,
      review_note: notes,
      updated_by: displayName,
    }
    const response = await reviewTestAssets(assetReviewUpdatePayload)
    if (response) {
      onReviewSuccessCallback()
      setQuality('')
      setNotes('')
    }
  }

  useEffect(() => {
    setQuality(quality_status)
    setNotes(review_note ?? '')
  }, [quality_status, review_note])

  return (
    <>
      <Grid container className={classes.promptInfoClass}>
        <Grid item>
          <div className={classes.lightLabelClass}>{'Category'}</div>
          <div
            className={
              isBulkViewMode
                ? classes.promptInfoBulkValue
                : classes.promptInfoValueClass
            }
          >
            {bulkTestReviewData?.['prompt_category'] || 'NA'}
          </div>
        </Grid>
        <Grid item>
          <div className={classes.lightLabelClass}>{'Context'}</div>
          <div
            className={
              isBulkViewMode
                ? classes.promptInfoBulkValue
                : classes.promptInfoValueClass
            }
          >
            {bulkTestReviewData?.['context'] || 'NA'}
          </div>
        </Grid>
        <Grid item>
          <div className={classes.lightLabelClass}>{'Prompt'}</div>
          <div
            className={
              isBulkViewMode
                ? classes.promptInfoBulkValue
                : classes.promptInfoValueClass
            }
          >
            {bulkTestReviewData?.['prompt'] || 'NA'}
          </div>
        </Grid>
        {
          (isAdGroupSuperAdmin || isLibrarian) ? (
            <Grid item container className={classes.modelSettingClass}>
              <Grid item className={classes.modelSettingItemClass}>
                <div className={classes.lightLabelClass}>{'Temperature'}</div>
                <div
                  className={
                    isBulkViewMode
                      ? classes.promptInfoBulkValue
                      : classes.promptInfoValueClass
                  }
                >
                  {bulkTestReviewData?.['temperature'] || 'NA'}
                </div>
              </Grid>
              <Grid item className={classes.modelSettingItemClass}>

                <div className={classes.lightLabelClass}>{'TOP_P'}</div>
                <div
                  className={
                    isBulkViewMode
                      ? classes.promptInfoBulkValue
                      : classes.promptInfoValueClass
                  }
                >
                  {bulkTestReviewData?.['top_p'] || 'NA'}
                </div>
              </Grid>
              <Grid item className={classes.modelSettingItemClass}>
                <div className={classes.lightLabelClass}>{'Seed'}</div>
                <div
                  className={
                    isBulkViewMode
                      ? classes.promptInfoBulkValue
                      : classes.promptInfoValueClass
                  }
                >
                  {bulkTestReviewData?.['seed'] || 'NA'}
                </div>
              </Grid>
            </Grid>
          ) : ''
        }
        {isBulkViewMode && (
          <Grid item className={classes.bulkResponseClass}>
            <div className={classes.lightLabelClass}>{'Response'}</div>
            <div
              className={
                isBulkViewMode
                  ? classes.promptInfoBulkValue
                  : classes.promptInfoValueClass
              }
            >
              {response ? <Markdown>{response}</Markdown> : ''}
            </div>
          </Grid>
        )}
      </Grid>
      <Grid container className={classes.reviewFieldsContainer}>
        <Grid item>
          <FormControl>
            <FormLabel id="quality-radio-buttons-group">Quality</FormLabel>
            <RadioGroup
              row
              aria-labelledby="quality-radiobuttons-group"
              name="quality-radio-buttons-group"
              value={quality}
              onChange={(event) => setQuality(event.target.value)}
            >
              <FormControlLabel value="PASS" control={<Radio />} label="Pass" />
              <FormControlLabel value="FAIL" control={<Radio />} label="Fail" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            id="test_notes"
            label="Notes"
            placeholder="Add any notes"
            InputLabelProps={{ shrink: true }}
            value={notes}
            fullWidth
            multiline
            minRows={4}
            onChange={(event) => setNotes(event.target.value)}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={classes.submitButton}
            onClick={() => onSaveReview()}
            disabled={selectedAssets?.length && quality ? false : true}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default BulkAssetReviewForm
