import PropTypes from 'prop-types'

export const FormikFieldPropTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.any,
  }),
  form: PropTypes.shape({
    dirty: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    isValid: PropTypes.bool,
    isValidating: PropTypes.bool,
    status: PropTypes.any,
    submitCount: PropTypes.number,
    errors: PropTypes.object,
  }),
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  classes: PropTypes.object,
}
export const FILE_CONVERSION_CONST = 1000
export const UPLOAD_FILE_SIZE_LIMIT =
  2 * FILE_CONVERSION_CONST * FILE_CONVERSION_CONST * FILE_CONVERSION_CONST
export const PROJECT_FILTER_ALL = 'All'
export const PROJECT_FILTER_CURRENT_PROJECTS = 'current projects'
export const PROJECT_FILTER_COMPLETED_PROJECTS = 'completed projects'
export const PROJECT_FILTER_WITH_CURRENT_TASKS = 'current tasks'
export const PROJECT_SORT_RUN_DATE = 'run_date'
export const PROJECT_SORT_OPTIONS = [
  {
    label: 'Run Date | New to Old',
    value: 'runDateDesc',
    apiValue: 'run date',
    order: 'desc',
  },
  {
    label: 'Run Date | Old to New',
    value: 'runDateAsc',
    apiValue: 'run date',
    order: 'asc',
  },
  {
    label: 'Due Date | New to Old',
    value: 'dueDateDesc',
    apiValue: 'due date',
    order: 'desc',
  },
  {
    label: 'Create Date | New to Old',
    value: 'createDateDesc',
    apiValue: 'create date',
    order: 'desc',
  },
  {
    label: 'Create Date | Old to New',
    value: 'createDateAsc',
    apiValue: 'create date',
    order: 'asc',
  },
]

export const RETRY_LIMIT_EXCEEDED = 'Retry Limit Exceeded (3)'
export const RETRY_IN_PROGRESS = 'Retry in progress. Please check after 10 minutes'
export const RETRY_INITIATED = 'Retry Selected Assets Initiated...'
export const RETRY_FAILED = 'Retry operation failed. Please contact admin...'
export const NO_ASSETS_FOUND_FOR_RETRY = 'No Assets Found for Retrying'
export const NO_STATUS_FOUND_TEXT = 'NA'
export const ERROR_MESSAGE_LABEL = 'Error Message:'
export const LAST_RETRIED_AT_LABEL = 'Last Retried At:'
export const RETRY_TIME_LIMIT = 600000 
export const RETRY_MAX_COUNT = 3


//Roundel 
export const ROUNDEL_CLIENT_FILES = 'Roundel Client Files'
export const ROUNDEL_FINAL_FILES = 'Roundel Final Files'