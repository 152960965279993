import { makeStyles } from '@mui/styles'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material'
import HeaderTitle from '../../components/Header/HeaderTitle'
import { generateBreadcrumb } from '../../helpers/stringHelper'
import { NEW_TEST, getGenAiBreadCrumbList } from '../helpers/genaiTestHelper'
import {
  availableAiModels,
  DEFAULT_GENAI_MODEL,
  promptCategoryList,
} from '../constants/genai'
import apiConfig from '../../config/apiConfig'
import { appendQueryParams } from '../../helpers/UrlHelper'
import NoThumbnail from '../../images/NoThumbnail.svg?url'
import {
  createBulkTest,
  fetchAIAllowedBoards,
  fetchBoardAssets,
} from '../services/genaiService'
import { isEmptyObject } from '../../helpers/UtilityHelper'
import { BULK_TESTS_VIEW_AI } from '../../constants/routes'

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 10 + 8,
      minWidth: 250,
      maxWidth: 600,
    },
  },
}

const useStyles = makeStyles({
  bulkDetailsContainer: {
    display: 'flex',
    padding: '20px 40px',
    borderBottom: '1px solid #D6D6D6',
  },
  bulkTestDetailClass: {
    display: 'flex',
    justifyContent: 'start',
    gap: '20px',
  },
  bulkTestNameClass: {
    width: '300px',
  },
  bulkTestPromptingClass: {
    display: 'flex',
    flexDirection: 'row',
    height: '82vh',
  },
  imageContainerClass: {
    width: '70%',
    borderRight: '1px solid #D6D6D6',
    borderBottom: '1px solid #D6D6D6',
  },
  promptFieldsClass: {
    width: '28%',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    padding: '10px 10px 10px 20px',
  },
  textAreaInputClass: {
    width: '100%',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
  },
  submitButton: {
    backgroundColor: '#178295',
    color: 'white',
    '&:disabled': {
      color: 'grey',
      backgroundColor: '#7f889994',
    },
    '&:hover': {
      backgroundColor: '#178295',
      color: 'white',
    },
  },
  transparentButton: {
    backgroundColor: 'white',
    color: '#178295',
    border: '1px solid #178295',
    '&:hover': {
      backgroundColor: 'white',
      color: '#178295',
    },
  },
  imageOptionClass: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    padding: '10px 15px',
    borderBottom: '1px solid #D6D6D6',
  },
  promptImages: {
    paddingLeft: '15px',
    display: 'flex',
    flexWrap: 'wrap',
    height: '80vh',
    width: '100%',
    overflow: 'scroll',
    background: '#a396a30d',
  },
  bulkImage: {
    display: 'flex',
    alignItems: 'start',
  },
  newInput: {
    width: '120px',
  },
  imageDiv: {
    width: '130px',
    padding: '10px 0px',
  },
  imageStyles: {
    width: '100px',
    height: '100px',
    objectFit: 'contain',
    border: '1px solid #5e959280',
    overflow: 'hidden',
  },
})

function NewBulkTestView() {
  const classes = useStyles()
  const navigate = useNavigate()
  const bulkTestBreadCrumbs = getGenAiBreadCrumbList(NEW_TEST)
  const { displayName = '', isAdGroupSuperAdmin = false, isLibrarian = false  } = useSelector((state) => state.auth)

  const [testNameInput, setTestNameInput] = useState('')
  const [category, setCategory] = useState('')
  const [aiModel, setAiModel] = useState(DEFAULT_GENAI_MODEL)
  const [context, setContext] = useState('')
  const [promptQueryValue, setPromptQueryValue] = useState('')
  const [temperature, setTemperature] = useState('1.0')
  const [topP, setTopP] = useState('1.0')
  const [seed, setSeed] = useState('20000')

  const [availableBoardList, setAvailableBoardList] = useState([])
  const [selectedBoard, setSelectedBoard] = useState({})
  const [selectedBoardAssets, setSelectedBoardAssets] = useState([])

  const [imageLoader, setImageLoader] = useState(false)

  useEffect(() => {
    fetchAIAllowedBoards().then((response) =>
      setAvailableBoardList(response.data),
    )
    const savedContext = localStorage.getItem('ai_context')
    if (savedContext) {
      setContext(savedContext)
    }
  }, [])

  useEffect(() => {
    const existingContext = localStorage.getItem('ai_context')
    if (existingContext !== context) {
      localStorage.setItem('ai_context', context)
    }
  }, [context])

  useEffect(() => {
    if (!isEmptyObject(selectedBoard)) {
      const { board_id = '' } = selectedBoard
      if (board_id) {
        callBoardAssetsApi(board_id)
      }
    }
  }, [selectedBoard])

  const callBoardAssetsApi = (selectedBoardId = '') => {
    setImageLoader(true)
    fetchBoardAssets([selectedBoardId])
      .then((response) => {
        const selectedBoardAssetsResponse = response.data
        const assetDetails = Object.values(selectedBoardAssetsResponse).flat(1)
        const aiTestingAssets = assetDetails.map((asset) => {
          const { asset_id = '', render_urls = {} } = asset
          return {
            asset_id: asset_id,
            asset_url:
              render_urls?.preview_asset_url ??
              render_urls?.thumbnail_asset_url,
          }
        })
        setImageLoader(false)
        setSelectedBoardAssets(aiTestingAssets)
      })
      .catch(() => {
        setImageLoader(false)
      })
  }

  const onGenerateText = () => {
    const { board_id = '', board_name = '' } = selectedBoard
    const selectedBoardDetails = [{ board_id, board_name }]
    const createTestPayload = {
      created_by: displayName,
      model_used: aiModel,
      test_name: testNameInput,
      prompt_category: category,
      context: context,
      prompt: promptQueryValue,
      assets: selectedBoardAssets,
      board_list: selectedBoardDetails,
      test_notes: '',
    }
    if(isAdGroupSuperAdmin || isLibrarian) {
      createTestPayload['temperature'] = parseFloat(temperature)
      createTestPayload['seed'] = parseFloat(seed)
      createTestPayload['top_p'] = parseFloat(topP)
    }
    createBulkTest(createTestPayload).then(() => {
      navigate(BULK_TESTS_VIEW_AI)
    })
  }

  return (
    <>
      <HeaderTitle
        title={`AI - Image To Text : ${generateBreadcrumb(bulkTestBreadCrumbs)}`}
      />
      <Grid container>
        <Grid item container className={classes.bulkDetailsContainer}>
          <Grid item container className={classes.bulkTestDetailClass}>
            <Grid item className={classes.bulkTestNameClass}>
              <TextField
                required
                id="test-name"
                label="Test Name"
                placeholder="Enter Test Name"
                InputLabelProps={{ shrink: true }}
                value={testNameInput}
                fullWidth
                onChange={(event) => setTestNameInput(event.target.value)}
              />
            </Grid>
            <Grid item>
              <FormControl size="small">
                <InputLabel id="modelused-label">Model Used</InputLabel>
                <Select
                  style={{ width: '250px' }}
                  labelId="modelused-label"
                  id="modelused-label"
                  size="large"
                  value={aiModel}
                  input={<OutlinedInput label="Model Used" />}
                  onChange={(event) => setAiModel(event.target.value)}
                >
                  {availableAiModels.map((model) => (
                    <MenuItem value={model}>{model}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl size="small">
                <Autocomplete
                  style={{ maxWidth: '600px', minWidth: '250px' }}
                  freeSolo
                  id="boards_autocomplete"
                  disableClearable
                  options={availableBoardList}
                  getOptionLabel={(option) => option?.board_name || ''}
                  value={selectedBoard}
                  defaultValue={''}
                  onChange={(event, newValue) => {
                    setSelectedBoard(newValue)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Boards"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            {
              (isAdGroupSuperAdmin || isLibrarian) ? (
                <>
                  <Grid item>
                    <TextField
                      id="temperature"
                      label="Temperature"
                      InputLabelProps={{ shrink: true }}
                      value={temperature}
                      className={classes.newInput}
                      onChange={(event) => setTemperature(event.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="top_p"
                      label="top_p"
                      InputLabelProps={{ shrink: true }}
                      value={topP}
                      className={classes.newInput}
                      onChange={(event) => setTopP(event.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="seed"
                      label="Seed"
                      InputLabelProps={{ shrink: true }}
                      value={seed}
                      className={classes.newInput}
                      onChange={(event) => setSeed(event.target.value)}
                    />
                  </Grid>
                </>
              ) : ''
            }
          </Grid>
        </Grid>

        <Grid item container className={classes.bulkTestPromptingClass}>
          <Grid item container className={classes.imageContainerClass}>
            <Grid item container className={classes.imageOptionClass}>
              {isEmptyObject(selectedBoard)
                ? 'No Boards Selected for Testing'
                : `${selectedBoardAssets.length} Images`}
            </Grid>
            <Grid item className={classes.promptImages}>
              {imageLoader ? (
                <CircularProgress />
              ) : (
                (selectedBoardAssets || []).map((assetObj, index) => {
                  const { asset_url = '', asset_id = '' } = assetObj
                  const imageQueryParams = { key: apiConfig.key }
                  const imageUrl = asset_url?.includes('key')
                    ? asset_url
                    : appendQueryParams(asset_url, imageQueryParams)

                  return (
                    <Grid item className={classes.bulkImage}>
                      <div item className={classes.imageDiv}>
                        <img
                          src={`${imageUrl}`}
                          alt={asset_id}
                          className={classes.imageStyles}
                          onError={(e) => {
                            e.target.src = NoThumbnail
                          }}
                        />
                      </div>
                    </Grid>
                  )
                })
              )}
            </Grid>
          </Grid>
          <Grid item container className={classes.promptFieldsClass}>
            <Grid item>
              Generate Text for{' '}
              {selectedBoardAssets.length ? selectedBoardAssets.length : ''}{' '}
              Selected Images
            </Grid>
            <Grid item>
              <FormControl size="small">
                <InputLabel id="category-label">Category</InputLabel>
                <Select
                  style={{ width: '250px', height: '50px' }}
                  labelId="category-label"
                  id="category-label"
                  value={category}
                  input={<OutlinedInput label="Category" />}
                  onChange={(event) => setCategory(event.target.value)}
                >
                  {promptCategoryList.map((category) => (
                    <MenuItem value={category}>{category}</MenuItem>
                  ))}
                </Select>
                {!category && (
                  <FormHelperText
                    style={{ margin: '0px', paddingTop: '3px', color: 'red' }}
                  >
                    * Select Category for your prompt
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                required
                id="test-name"
                label="Context"
                placeholder="Think about the context of your prompt and what you would like AI to consider when generating a response. If you’re looking for a specific style or an output, mention it here to guide the AI effectively and consistently..."
                InputLabelProps={{ shrink: true }}
                value={context}
                fullWidth
                multiline
                minRows={4}
                onChange={(event) => setContext(event.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                required
                id="test-name"
                label="Prompt"
                placeholder="Enter your prompt..."
                InputLabelProps={{ shrink: true }}
                value={promptQueryValue}
                fullWidth
                multiline
                minRows={6}
                onChange={(event) => setPromptQueryValue(event.target.value)}
              />
            </Grid>
            <Grid item className={classes.buttonGroup}>
              <Button
                variant="contained"
                className={classes.submitButton}
                onClick={onGenerateText}
                disabled={
                  !(
                    promptQueryValue &&
                    category &&
                    testNameInput &&
                    selectedBoardAssets.length
                  )
                }
              >
                Generate Text
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default NewBulkTestView
