import React from 'react'
import { connect } from 'react-redux'
import { Route, Routes, Navigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import clsx from 'clsx'
import { isEmpty } from 'lodash'
import { useTheme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import Drawer from '@mui/material/Drawer'
import { withEnv } from '@praxis/component-runtime-env'
import { Alert, Button, Grid, Link } from '@mui/material'
import { withAuth, ProtectedElement } from '@praxis/component-auth'

import withRouter from '../../containers/Router/WithRouter'
import Notifications from '../Notifications/Notifications'
import {
  closeSideNav,
  openSideNav,
  toggleRightSidenav,
  setSaveAlertAction,
} from '../../store/layout/actionCreator'
import AppHeader from '../Header/Header'
import Upload from '../Upload'
import NoAccessPage from '../NoAccessPage/NoAccessPage'
import AssetHubWhiteLogo from '../../images/AssetHubLogo-White.svg?url'
import './layout.css'
import { Header } from '../SideNav'
import Sidebar from './Sidebar'
import AHSideNav from './AHSideNav'
import {
  selectUserEmail,
  selectUserId,
  selectUserCompany,
} from '../../store/auth/selector'
import {
  selectIsToggleRightSidenav,
  selectRightSideNavComponent,
} from '../../store/layout/selector'
import {
  fetchUserPreferences,
  updateUser,
} from '../../store/preferences/actionCreator'
import { setAuthData, closeNoAccessPopUp } from '../../store/auth/actions'
import { isAuthorizedToPage } from '../../helpers/UtilityHelper'
import { isSessionActive } from '../../helpers/sessionHelper'
import { RIGHT_SIDEBAR_WIDTH } from '../../constants/search'

import firefly from '../../analytics/firefly'
import navRoutes from './routes'
import { fetchSearchMetadata } from '../../betasearch/store/search/searchSlice'
import { BetaAnalytics, trackBetaSearchAnalytics } from '../../analytics/betaSearch'

const styles = makeStyles((theme) => ({
  header: {
    height: 56,
    [`${useTheme().breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: 48,
    },
    [useTheme().breakpoints.up('sm')]: {
      height: 64,
    },
  },
  paper: {
    width: '210px !important',
  },
  dialogContainer: {
    position: 'relative',
    minHeight: '100%',
    marginTop: 0,
  },
  contentContainer: {
    flexGrow: 1,
    marginLeft: 70,
  },
  alertBanner: {
    paddingLeft: '4%',
  },
  bannerText: {
    justifyContent: 'center',
  },
  customBannerText: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#A9E5A9',
    color: '#1D4924',
    height: '40px',
    fontSize: '16px'
  },
  sideNavFooterList: {
    color: useTheme().typography.body1.color,
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: useTheme().typography.caption.fontSize,
    listStyle: 'none',
    margin: 0,
    padding: useTheme().spacing(1.5),
    '& li': {
      padding: useTheme().spacing(1 / 2),
    },
    '& a': {
      color: useTheme().typography.caption.color,
      textDecoration: 'none',
      transition: useTheme().transitions.create('color', {
        duration: useTheme().transitions.duration.shorter,
      }),
      '&:hover': {
        color: useTheme().palette.primary[500],
      },
    },
  },
  rightIcon: {
    marginLeft: useTheme().spacing(),
  },
  loginPage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '600px',
    flexDirection: 'column',
  },
  avatar: {
    marginLeft: -30,
    width: 200,
    height: 200,
    backgroundColor: '#111',
  },
  button: {
    margin: useTheme().spacing(),
  },
  logotext: {
    fontSize: '33px',
    height: '24px',
    backgroundColor: '#43425D',
    padding: '15px 15px 50px 7px',
    margin: 0,
    color: '#fff',
  },
  activeLink: {
    backgroundColor: '#3A3952',
    borderLeft: '5px solid #188295',
    padding: '8px 0px 4px 11px',
    color: 'white !important',
  },
  sideNavIcon: {
    marginRight: -5,
    minWidth: '10px',
  },
  text: {
    paddingLeft: '32px',
    fontSize: '16px',
    whiteSpace: 'nowrap',
  },
  menuItem: {
    padding: '8px 0px 4px 16px',
    '&:hover': {
      backgroundColor: 'rgba(158,158,158,.2)',
    },
    color: useTheme().palette.primary.lighterGrey,
  },
  drawerPaper: {
    backgroundColor: '#43425D',
  },
  drawerOpen: {
    width: 240,
    transition: useTheme().transitions.create('width', {
      easing: useTheme().transitions.easing.sharp,
      duration: useTheme().transitions.duration.standard,
    }),
  },
  drawerClose: {
    width: '72px !important',
    transition: useTheme().transitions.create('width', {
      easing: useTheme().transitions.easing.sharp,
      duration: useTheme().transitions.duration.standard,
    }),
    [useTheme().breakpoints.up('sm')]: {
      width: useTheme().spacing(9) + 1,
    },
    overflow: 'hidden',
  },
  sideNavImg: {
    width: '26px',
    height: '24px',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '5px',
    color: '#BDBDBD',
  },
  sideNavText: {
    width: '26px',
    height: '24px',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '10px',
    color: 'white',
  },
  activeSideNavImg: {
    width: '26px',
    height: '24px',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '5px',
    color: 'white',
  },
  userBarImg: {
    width: '26px',
    height: '24px',
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '5px',
    color: useTheme().palette.primary.lighterGrey,
  },
  hoverDiv: {
    height: '100%',
    cursor: 'pointer',
  },
  userBar: {
    backgroundColor: '#43425D',
    paddingLeft: '4px',
    color: useTheme().palette.primary.lighterGrey,
  },
  help: {
    width: '57px',
    padding: '8px 0px 4px 16px',
    position: 'absolute',
    bottom: '50px',
    '&:hover': {
      backgroundColor: 'rgba(158,158,158,.2)',
    },
    color: useTheme().palette.primary.lighterGrey,
  },
  helpActiveLink: {
    width: '57px',
    padding: '8px 0px 4px 11px',
    position: 'absolute',
    bottom: '50px',
    backgroundColor: '#3A3952',
    borderLeft: '5px solid #188295',
    color: useTheme().palette.primary.lighterGrey,
  },
  customButtonGreen: {
    marginLeft: '16px',
    padding: '8px',
    backgroundColor: '#1D4924',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#1D4924',
      color: '#FFFFFF',
    }
  }
}))

const MyComponent = (props) => {
  const classes = styles()
  return <Layout {...props} classes={classes} />
}
// function MyComponent(component) {
//   // Pass the props as an argument of useStyles()
//   const classes = styles()

//   component.defaultProps = {
//     ...component.defaultProps,
//     classes,
//   }
//   return component
// }

export class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.tab = 'home'

    this.state = {
      open: false,
    }
  }

  checksOnAuthenticationSuccess = () => {
    const {
      login = () => {},
      user: { lanId = '' },
    } = this.props
    const isSessionExist = isSessionActive(lanId, window.location.hostname)
    if (!isSessionExist) {
      login({ redirect: window.location.href })
    } else {
      this.onUserLoggedIn()
    }
  }

  onUserLoggedIn = () => {
    const {
      fetchUserPreferences = () => {},
      fetchSearchMetadata = () => {},
      router = {},
      session = {},
      auth = {},
    } = this.props
    const {
      location: { pathname: locationPath = '' },
    } = router
    fetchUserPreferences()
    fetchSearchMetadata()
    trackBetaSearchAnalytics({
      auth: auth, trackingData: {
        eventType: BetaAnalytics.event.PAGELOAD,
        eventName: BetaAnalytics.event.HOMEPAGE,
        eventData: {
          key: BetaAnalytics.event.PAGELOAD,
          value: BetaAnalytics.event.HOMEPAGE,
        }
      }
    })
    const currentTitle = locationPath.split('/')[1] || 'home'
    if (currentTitle === 'search') {
      firefly.initalizeSearchId()
    }
    const currentTab =
      ['project', 'annotator'].indexOf(currentTitle) !== -1
        ? 'projects'
        : currentTitle
    this.tab = currentTab
  }

  async componentDidMount() {
    const {
      session = {},
      login = () => {},
      isAuthorized = () => {},
      setAuthData = () => {},
    } = this.props
    const { hasExpired = false } = session || {}
    if (hasExpired || !isAuthorized()) {
      login({ redirect: window.location.href })
    } else {
      await setAuthData(session)
      this.checksOnAuthenticationSuccess()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      router: prevRouter = {},
      user: { lanId: prevLanId = '' },
    } = prevProps
    const { location: { pathname: prevPathname = '' } = {} } = prevRouter
    const { router = {} } = this.props
    const { location: { pathname: locationPath = '' } = {} } = router
    if (prevPathname !== locationPath) {
      this.trackView(prevPathname)
      const currentTitle = locationPath.split('/')[1]
      const previousTitle = prevPathname.split('/')[1]
      if (currentTitle !== previousTitle && currentTitle === 'search') {
        firefly.initalizeSearchId()
      } else {
        if (currentTitle !== 'search') {
          firefly.clearSearchId()
        }
      }
      if (currentTitle && currentTitle !== previousTitle) {
        if (['project', 'annotator'].indexOf(currentTitle) !== -1) {
          this.tab = 'projects'
        } else if (currentTitle === 'publishing') {
          this.tab = 'publish'
        } else {
          this.setState({ tab: currentTitle })
          this.tab = currentTitle
        }
      } else if (isEmpty(currentTitle)) {
        this.tab = 'home'
      }
    }
    const {
      auth = {},
      user: { lanId = '' },
      updateUser = () => {},
    } = this.props
    if (lanId) {
      isSessionActive(lanId, window.location.hostname)
    }
    if (prevLanId !== lanId) {
      updateUser(auth)
    }
  }

  handleNavigation = () => {
    const { env: {
      BANNER_INFO: {
        BANNER_REFERENCE_LINK = '',
      } = {}
    } = {},
      auth = {},
      router = {}
    } = this.props
    const { navigate = () => { } } = router
    if(BANNER_REFERENCE_LINK) {
      trackBetaSearchAnalytics({
        auth: auth, trackingData: {
          eventType: BetaAnalytics.event.BANNER_CLICKED,
          eventName: BetaAnalytics.event.BANNER_CLICKED,
          eventData: {
            key: BetaAnalytics.event.BANNER_CLICKED,
            value: BANNER_REFERENCE_LINK,
          }
        }
      })
      navigate(BANNER_REFERENCE_LINK)
    }
  }

  handleMenuButtonClick = () => {
    if (this.props.sideNavIsOpen) {
      this.props.closeSideNav()
    } else {
      this.props.openSideNav()
    }
  }

  isAuthorizedPageHandler = (path = '') => {
    const { auth = {}, role = '', env = {} } = this.props
    const { memberOf = [] } = auth
    const { REACT_APP_ROLES_CONFIG = {} } = env
    const member = role ? [role] : memberOf
    return isAuthorizedToPage(path, member, REACT_APP_ROLES_CONFIG)
  }

  trackView = (prevPath) => {
    const { router = {}, user = {} } = this.props
    const { lanId = '' } = user
    const { location = {} } = router
    const { pathname } = location
    const title = pathname.split('/')[1]
    firefly.trackView(pathname, title, lanId, prevPath)
  }

  // render() {
  //   return <div>test</div>
  // }
  render() {
    const {
      classes = {},
      user = {},
      headerTitle,
      headerIcon,
      isToggleRightSidenav,
      rightSideNavComponent,
      toggleRightSidenav = () => {},
      setSaveAlertAction = () => {},
      auth = {},
      logout = () => {},
      router = {},
      isAuthorized = () => {},
      env = {},
    } = this.props
    const {
      location: { pathname: locationPath = '' },
    } = router
    const { open = false } = this.state
    const { REACT_APP_ROLES_CONFIG = {}, BANNER_INFO = {} } = env
    const {
      BANNER_TEXT = '',
      BANNER_ALERT_TYPE = '',
      BANNER_REFERENCE_LINK = '',
      BANNER_LINK_TEXT = '',
      BANNER_BUTTON_TEXT = ''
    } = BANNER_INFO
    const { assethubADGroups = [] } = REACT_APP_ROLES_CONFIG
    const { noAccessModalShown = false } = user
    const { isLoading = true } = auth
    const sidebarWidth =
      isToggleRightSidenav && rightSideNavComponent
        ? RIGHT_SIDEBAR_WIDTH[rightSideNavComponent] ||
        RIGHT_SIDEBAR_WIDTH['default']
        : '0'
    const mainLayoutStyle = { width: `calc(100% - ${sidebarWidth}px)` }
    const rightSideNavStyle = { width: `${sidebarWidth}px` }

    return isAuthorized() ? (
      <div>
        <Helmet defaultTitle="AssetHub" titleTemplate="AssetHub: %s" />
        <Notifications />
        <Drawer
          variant="permanent"
          className={clsx(classes.hoverDiv, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx(classes.drawerPaper, classes.hoverDiv, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <Header>
            <h1 className={classes.logotext}>
              <img
                src={AssetHubWhiteLogo}
                alt="AssetHub"
                style={{
                  width: '169px',
                  paddingLeft: '16px',
                  overflow: 'hidden',
                }}
              />
            </h1>
          </Header>
          {isAuthorized(assethubADGroups) ? (
            <AHSideNav classes={classes} tab={this.tab} />
          ) : (
            ''
          )}
        </Drawer>
        {isAuthorized(assethubADGroups) ? (
          <AppHeader
            title={headerTitle}
            icon={headerIcon}
            menuAction={() => this.handleMenuButtonClick()}
            showFeedBack
            user={user}
            signOut={logout}
          />
        ) : (
          <AppHeader
            title={headerTitle}
            icon={headerIcon}
            showFeedBack={false}
          />
        )}
        {!locationPath.includes('annotator') && BANNER_TEXT && (
          <div className={classes.alertBanner}>
            <Alert severity={BANNER_ALERT_TYPE} className={BANNER_ALERT_TYPE ? classes.bannerText : classes.customBannerText} icon={false}>
              {BANNER_TEXT + ` `}
              {BANNER_BUTTON_TEXT && BANNER_REFERENCE_LINK && (
                <Button onClick={() => this.handleNavigation()} variant='outlined' className={BANNER_ALERT_TYPE ? '' : classes.customButtonGreen}>
                  {BANNER_BUTTON_TEXT}
                </Button>
              )}
              {BANNER_LINK_TEXT && BANNER_REFERENCE_LINK && (
                <Link href={BANNER_REFERENCE_LINK}>
                  {BANNER_LINK_TEXT}
                </Link>
              )}
            </Alert>
          </div>
        )}
        {isAuthorized() && !isLoading ? (
          <div className={classes.contentContainer}>
            <Grid container>
              <Grid item style={mainLayoutStyle}>
                {noAccessModalShown ? (
                  <NoAccessPage />
                ) : (
                  <Routes>
                    {navRoutes.map((prop, key) => {
                      const { adGroups = () => {}, redirect = false } = prop
                      const adGroupArr = adGroups(env)
                      return (
                        <Route
                          key={key}
                          exact={prop.exact}
                          path={prop.path}
                          element={
                            redirect ? (
                              <Navigate to="/" replace />
                            ) : (
                              <ProtectedElement
                                allowed={adGroupArr}
                                unauthorizedRoute="/unauthorized"
                              >
                                <prop.component
                                  style={{ indicatorColor: 'primary' }}
                                  customPath={`/${prop.displayName}`}
                                  path={prop.path}
                                />
                              </ProtectedElement>
                            )
                          }
                        />
                      )
                    })}
                  </Routes>
                )}
              </Grid>
              {isToggleRightSidenav && (
                <Grid item style={rightSideNavStyle}>
                  <Grid
                    container
                    spacing={0}
                    className={classes.dialogContainer}
                    style={{ zIndex: '99' }}
                  >
                    <Grid item xs={12}>
                      <Sidebar
                        rightSideNavComponent={rightSideNavComponent}
                        isToggleRightSidenav={isToggleRightSidenav}
                        toggleRightSidenav={toggleRightSidenav}
                        setSaveAlertAction={setSaveAlertAction}
                        router={router}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </div>
        ) : (
          ''
        )}
        <Upload router={router} />
      </div>
    ) : (
      ''
    )
  }
}

Layout.defaultProps = {
  open: true,
}

const mapStateToProps = (state = {}) => ({
  headerTitle: state.layout?.headerTitle,
  headerIcon: state.layout?.headerIcon,
  sideNavIsOpen: state.layout?.sideNavIsOpen,
  isToggleRightSidenav: selectIsToggleRightSidenav()(state),
  rightSideNavComponent: selectRightSideNavComponent()(state),
  user: {
    email: selectUserEmail()(state),
    noAccessModalShown: state.auth?.noAccessModalShown,
    lanId: selectUserId()(state),
    company: selectUserCompany()(state),
  },
  userPreferences: state.userPreferences,
  auth: state.auth,
  role: state.headerReducer?.role,
})

const mapDispatchToProps = {
  openSideNav,
  closeSideNav,
  toggleRightSidenav,
  setSaveAlertAction,
  fetchUserPreferences,
  fetchSearchMetadata,
  closeNoAccessPopUp,
  updateUser,
  setAuthData,
}

const mapAuthToProps = (auth) => ({
  ...auth,
})

export default withAuth(mapAuthToProps)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withEnv()(withRouter(MyComponent)))
)
