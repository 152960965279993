import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment-timezone'
import { debounce, keyBy } from 'lodash'
import firefly from '../analytics/firefly'
import { containsEmptyValue, sortMyArray } from './UtilityHelper'
import {
  ROUNDEL_CLIENT_FILES_DUE_DATE,
  ROUNDEL_CLIENT_FILES_TASK_NAME,
  ROUNDEL_CLIENT_FILES_UPLOADERS,
  ROUNDEL_FINAL_FILES_DUE_DATE,
  ROUNDEL_FINAL_FILES_TASK_NAME,
  ROUNDEL_FINAL_FILES_UPLOADERS,
} from '../constants/projectTask'
import { validateAssetRetry } from '../components/ProjectsPage/project/assets/helper'

export const memoizeProjectType = (arrayOfProjectTypes = []) => {
  if (!arrayOfProjectTypes.length) return []

  const unsortedMemoizedProjectTypes = arrayOfProjectTypes.reduce(
    (agg, projectType = {}) => {
      let memoizedProjectType = {}
      memoizedProjectType.displayText = get(projectType, 'display_text', '')
      memoizedProjectType.iconDescriptor = get(
        projectType,
        'icon_descriptor',
        '',
      )
      memoizedProjectType.metadataName = get(projectType, 'metadata_name', '')
      memoizedProjectType.metadataType = get(projectType, 'metadata_type', '')
      memoizedProjectType.projectType = get(projectType, 'project_type', '')
      memoizedProjectType.projectTypeSequence = get(
        projectType,
        'project_type_sequence',
        null,
      )

      if (!containsEmptyValue(memoizedProjectType)) {
        agg.push(memoizedProjectType)
      }
      return agg
    },
    [],
  )

  return sortMyArray(unsortedMemoizedProjectTypes, 'projectTypeSequence', 'asc')
}

export const memoizeFormData = (formData = {}) => {
  const formFields = get(formData, 'metadata_list', [])
  const memoizeFormFields = formFields
    .filter((field = {}) => {
      const fieldName = get(field, 'metadata_name', '')
      if (fieldName !== 'project_code' && fieldName !== 'release_date') {
        return field
      }
    })
    .map((field = {}) => ({
      metadataValue: get(field, 'metadata_value', ''),
      metadataName: get(field, 'metadata_name', ''),
      metadataType: get(field, 'metadata_type', ''),
      displayText: get(field, 'display_text', ''),
      iconDescriptor: get(field, 'icon_descriptor', 'Label'),
      isRequired: get(field, 'is_required', false),
      fieldSequence: get(field, 'field_sequence', 0),
      values: get(field, 'values', []).map((x) => ({ text: x, value: x })),
    }))
  const sortedFormFields = sortMyArray(
    memoizeFormFields,
    'fieldSequence',
    'asc',
  )
  return {
    projectType: get(formData, 'project_type', ''),
    projectId: get(formData, 'project_id', ''),
    projectUuid: get(formData, 'project_uuid', null),
    uoiId: get(formData, 'uoi_id', null),
    projectInitiator: get(formData, 'project_initiator', null),
    confidential: get(formData, 'confidential', false),
    metadataList: sortedFormFields,
  }
}

export const convertFormToAPIData = (values = {}) => {
  const { projectTypeDictionary = {} } = values
  let apiData = {}
  const projectType = get(values, 'projectType', '')
  const channelISMP = get(values, 'channel_ISMP', '')

  const channel = get(values, 'channel', '')
  apiData['channel'] = channel ? channel : channelISMP
  if (projectType === 'Experiential Marketing') {
    apiData['channel'] = 'Experiential' //default value
  }

  apiData['brand'] = get(values, 'brand', '')
  apiData['campaign'] = get(values, 'campaign', '')
  apiData['campaign_year'] = get(values, 'campaign_year', '')
  apiData['project_name'] = get(values, 'project_name', '')
  apiData['project_uuid'] = get(values, 'project_uuid', '')
  apiData['experiential_project_type'] = get(
    values,
    'experiential_project_type',
    '',
  )
  apiData['gift_card_type'] = get(values, 'gift_card_type', '')
  apiData['influencer_name'] = get(values, 'influencer_name', '')
  apiData['project_admin_updated'] = get(values, 'project_admin_updated', false)

  const dueDate = get(values, 'due_date', moment())
  const cleanDueDate = moment(dueDate)
  apiData['due_date'] = dueDate ? new Date(cleanDueDate.format()) : new Date()

  const runDate = get(values, 'run_date', moment())
  const cleanRunDate = moment(runDate)

  apiData['run_date'] = runDate ? new Date(cleanRunDate.format()) : new Date()
  apiData['project_type'] = projectType

  if (projectType === 'Weekly Ad') {
    apiData['project_id'] = moment(
      new Date(runDate).toISOString().substring(0, 10),
    )
      .utc()
      .local()
      .format('YYYYMMDD')
  } else if (projectTypeDictionary[projectType] === 'date') {
    apiData['project_id'] = runDate
      ? new Date(cleanRunDate.format())
      : new Date()
  } else {
    apiData['project_id'] = get(values, 'project_id', '')
  }

  apiData['project_admins'] = get(values, 'project_admins', []).filter(
    (admin) => !get(admin, 'isBadEmail', false),
  )

  const projectRelaseDate = get(values, 'project_release_date', null)
  // Project release date maybe null or Moment. If Moment, return formatted value, otherwise return null
  apiData['project_release_date'] = projectRelaseDate
    ? new Date(projectRelaseDate.format())
    : projectRelaseDate
  apiData['project_code'] = get(values, 'project_code', '')
  apiData['confidential'] = get(values, 'confidential', false)

  if (projectType && ['Other', 'CreativeHub'].indexOf(projectType) !== -1) {
    apiData['project_id'] = new Date()
  }

  if (projectType === 'Roundel Final Files') {
    const tasksInfo = getRoundelTaskInfo(values, apiData)
    if (tasksInfo.length) {
      apiData['upload_task_details'] = tasksInfo
    }
  }

  return apiData
}

const getRoundelTaskInfo = (values = {}, apiData = {}) => {
  let tasksInfo = []
  const clientFileUploaderList = get(values, ROUNDEL_CLIENT_FILES_UPLOADERS, [])
  if (clientFileUploaderList.length) {
    const clientFileDueDate = get(
      values,
      ROUNDEL_CLIENT_FILES_DUE_DATE,
      moment(),
    )
    const cleanClientFileDueDate = moment(clientFileDueDate)
    const clientTaskDetails = {}
    clientTaskDetails['task_name'] = ROUNDEL_CLIENT_FILES_TASK_NAME
    clientTaskDetails['user_details'] = modifyUploadersToTaskUsers(
      clientFileUploaderList,
      apiData['project_admins'],
    )
    clientTaskDetails['task_due_date'] = clientFileDueDate
      ? new Date(cleanClientFileDueDate.format())
      : new Date()
    tasksInfo.push(clientTaskDetails)
  }
  const finalFileUploaderList = get(values, ROUNDEL_FINAL_FILES_UPLOADERS, [])
  if (finalFileUploaderList.length) {
    const finalFileDueDate = get(values, ROUNDEL_FINAL_FILES_DUE_DATE, moment())
    const cleanFinalFileDueDate = moment(finalFileDueDate)
    const finalTaskDetails = {}
    finalTaskDetails['task_name'] = ROUNDEL_FINAL_FILES_TASK_NAME
    finalTaskDetails['user_details'] = modifyUploadersToTaskUsers(
      finalFileUploaderList,
      apiData['project_admins'],
    )
    finalTaskDetails['task_due_date'] = finalFileDueDate
      ? new Date(cleanFinalFileDueDate.format())
      : new Date()
    tasksInfo.push(finalTaskDetails)
  }
  return tasksInfo
}

const modifyUploadersToTaskUsers = (uploadersList = [], admins = []) => {
  const {
    adminUsers = [],
    adminGroups = [],
    uploaderGroups = [],
    uploaderUsers = [],
  } = getProjectUserDetails(uploadersList, admins)

  let userDetails = {
    admins: {
      user: adminUsers,
      group: adminGroups,
    },
    observers: {
      group: [],
      user: [],
    },
    reviewers: {
      group: [],
      user: [],
    },
    uploaders: {
      group: uploaderGroups,
      user: uploaderUsers,
    },
  }
  return userDetails
}

const getProjectUserDetails = (uploadersList = [], adminsList = []) => {
  const adminUsers = []
  const adminGroups = []
  const uploaderGroups = []
  const uploaderUsers = []

  uploadersList.forEach((uploaders = {}) => {
    const {
      email_address = '',
      group_id = '',
      first_name = '',
      last_name = '',
      login_id = '',
      user_id = '',
    } = uploaders
    const hasGroupId = group_id !== null && !!group_id
    if (hasGroupId) {
      uploaderGroups.push({ id: group_id, role_id: 50 })
    } else {
      uploaderUsers.push({
        first_name,
        last_name,
        login_id,
        role_id: 50,
        role_name: 'uploader',
        user_id,
        user_email_address: email_address,
      })
    }
  })

  adminsList.forEach((uploaders = {}) => {
    const {
      email_address = '',
      group_id = '',
      first_name = '',
      last_name = '',
      login_id = '',
      role = '',
      user_id = '',
    } = uploaders
    const hasGroupId = group_id !== null && !!group_id
    if (hasGroupId) {
      adminGroups.push({ id: group_id, role_id: 40 })
    } else {
      adminUsers.push({
        first_name,
        last_name,
        login_id,
        role_id: 40,
        role_name: 'Admin',
        user_id,
        user_email_address: email_address,
      })
    }
  })

  return {
    adminUsers,
    adminGroups,
    uploaderGroups,
    uploaderUsers,
  }
}

export const isExtantProject = (formData = {}) => {
  const { projectInitiator } = formData
  return projectInitiator !== null && !isEmpty(projectInitiator)
}

export const createProjectTypeDictionary = (arrayOfProjectTypes = []) =>
  arrayOfProjectTypes.reduce((dictionary, projectTypeObject = {}) => {
    const { project_type = null, metadata_type = null } = projectTypeObject

    if (project_type && metadata_type) {
      return { ...dictionary, [project_type]: metadata_type }
    }
  }, {})

export const projectListFilterEventDebounced = debounce(
  (pagination = {}, lanId = '', searchProjects = () => {}) => {
    searchProjects(pagination, lanId)
    firefly.trackProjectListFilter(pagination.search_term, lanId)
  },
  600,
)

export const constructBreadCrumbObj = (project_name) =>
  project_name
    ? [
        {
          title: 'Projects',
          path: '/projects',
        },
        {
          title: project_name,
        },
      ]
    : [{ title: 'Projects Dashboard' }]

export const generateBreadCrumb = (arr = [], title) =>
  arr
    .map((y) =>
      y.path
        ? !title
          ? '<a style="text-decoration:none"  href="' +
            y.path +
            '">' +
            y.title +
            '</a>'
          : y.title
        : y.title,
    )
    .join(!title ? ' <span style="color: #ccc">/</span> ' : ' / ')

export const creativeAssetStatusFilter = [
  {
    value: 'All',
    label: 'All',
  },
  {
    value: 'WIP',
    label: 'WIP',
  },
  {
    value: 'Approved',
    label: 'Approved',
  },
]

export const validateIfAssetsAreProcessedAndWip = (selectedAssets = {}) => {
  let boolIsWipSelected = false
  if(!isEmpty(selectedAssets)) {

    boolIsWipSelected = Object.values(selectedAssets).
    filter((assets)=> !isEmpty(assets) && assets.status === "COMPLETE"
    ).every((assets) => 
      assets.creativeAssetStatus === "WIP"
    )
  }
  return boolIsWipSelected
}

export const getRetryRequestPayload = (selected = []) => {
  let finalPayload = []
  finalPayload = selected.map((selectedObj) => {
    const { asset_id = '', stepList = {} } = selectedObj
    let stepListArr = []
    for (const step in stepList) {
      if (stepList[step].status !== 'INDEXED') {
        stepListArr.push(step)
      }
    }
    return {
      asset_id,
      steps: stepListArr,
    }
  })
  return finalPayload
}

export const mergeLastUpdatedTimeToList = (existingList, responseData) =>
  existingList.map((assetDetails) => {
    const { asset_id = '', stepList = {}  } = assetDetails
    const filteredObj = responseData.find((data) => data.asset_id === asset_id)
    if (filteredObj) {
      const { updated_at = '' } = filteredObj
      const { allowRetry, retryDisableText } = validateAssetRetry(updated_at, stepList)
      return {
        ...assetDetails,
        updated_at: updated_at,
        retryDisableText,
        allowRetry
      }
    } else {
      return assetDetails
    }
  })

export const mergeFileNameToResponseList = (
  responseData = [],
  assetList = [],
) => {
  let res = []
  res = responseData.map((assetResponse) => {
    const commonObj = assetList.find(
      (assetDetails) =>
        assetDetails['asset_uoi_id'] == assetResponse['asset_id'],
    )
    const { asset_name = '' } = commonObj ?? {}
    const { asset_id = '', steps = [], updated_at = '' } = assetResponse
    const stepList = keyBy(steps, 'name')
    const { allowRetry, retryDisableText } = validateAssetRetry(updated_at, stepList)
    return {
      asset_id,
      asset_name,
      stepList,
      updated_at,
      allowRetry,
      retryDisableText,
    }
  })
  return res
}
