import * as React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import CopyToClipboard from 'react-copy-to-clipboard'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { withAuth } from '@praxis/component-auth'
import { withEnv } from '@praxis/component-runtime-env'
import {
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
  FormControl,
  RadioGroup,
  FormLabel,
  Radio,
  Select,
  InputLabel,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'
import { usaStatesInfo } from '../../helpers/UtilityHelper'
import { praxis } from '../../config/themeConfig'
import MultiLevelCheckBox from '../UsageRights/MultiLevelCheckBox'
import { UsageRightsLanguage } from './UsageRightsLanguage'
import { generateBreadcrumb } from '../../helpers/stringHelper'
import HeaderTitle from '../Header/HeaderTitle'
import AutoCompleteHolderName from './AutoCompleteHolderName'
import {
  selectIsAdGroupSuperAdmin,
  selectUserEmail,
} from '../../store/auth/selector'
import {
  getUsageRightsSummary,
  addNewOptionUsageRightsType,
  createNewRecordUsageRights,
  getUsageRightsOldData,
  addNewOptionUsageRightsName,
  addNewBuyer,
} from '../../store/usage_rights/action'
import {
  divideString,
  channelConvertion,
  convertIntoRequiredChannel,
  removeDifferences,
  convertToStringForm,
  resetChannels,
} from '../../helpers/usageRightsHelper'
import {
  formatUsageRightsDate,
  isMoment,
  formatExpirationDate,
} from '../../helpers/dateHelper'
import {
  INTERNAL_USAGE_CHANNEL_LABEL,
  OTHER_CHANNEL_LABEL,
} from '../../constants/usageRights'
import withRouter from '../../containers/Router/WithRouter'

const daySelection = [
  {
    value: 'Days',
    label: 'Days',
  },
  {
    value: 'Weeks',
    label: 'Weeks',
  },
  {
    value: 'Months',
    label: 'Months',
  },
  {
    value: 'Years',
    label: 'Years',
  },
]

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '24px',
  },
  paper: {
    padding: useTheme().spacing(2),
    textAlign: 'left',
    color: useTheme().palette.text.secondary,
  },
  styleInline: {
    display: 'inline-flex',
    margin: 8,
  },
  marginSpace: {
    marginRight: 4,
    fontWeight: 500,
    color: 'black',
  },
  longText: {
    padding: '16px 0 16px 0',
    fontSize: '15px',
    userSelect: 'none',
  },
  copyText: {
    padding: '16px 0 32px 0',
    textAlign: 'center',
  },
  createHeader: {
    fontSize: '22px',
    color: 'black',
    display: 'inline',
  },
  linkText: {
    textAlign: 'right',
    color: '#0D46A0',
    marginTop: '8px',
    cursor: 'pointer',
  },
  paperChipLeftSelected: {
    width: '110px',
    padding: '8px',
    margin: '16px 0 16px 0',
    borderRadius: '16px 0 0 16px',
    cursor: 'pointer',
    color: '#FFFFFF',
    backgroundColor: '#188295',
  },
  paperChipLeft: {
    width: '110px',
    padding: '8px',
    margin: '16px 0 16px 0',
    borderRadius: '16px 0 0 16px',
    cursor: 'pointer',
  },
  paperChipRightSelected: {
    width: '110px',
    padding: '8px',
    margin: '16px 0 16px 0',
    borderRadius: '0 16px 16px 0',
    cursor: 'pointer',
    color: '#FFFFFF',
    backgroundColor: '#188295',
  },
  paperChipRight: {
    width: '110px',
    padding: '8px',
    margin: '16px 0 16px 0',
    borderRadius: '0 16px 16px 0',
    cursor: 'pointer',
  },
  progress: {
    margin: '16px',
    marginLeft: '50%',
    marginTop: '20%',
    color: 'blue',
  },
  colorSwitchBase: {
    color: 'grey',
    '& + $colorBar': {
      backgroundColor: 'grey',
    },
    '&.Mui-colorChecked': {
      color: praxis.lighterBlue,
      '& + $colorBar': {
        backgroundColor: 'grey',
      },
    },
  },
  colorChecked: {},
  colorBar: {},
  buttonForSaveCreate: {
    backgroundColor: '#188295',
    '&:hover': {
      backgroundColor: '#188295',
    },
  },
  primaryColor: {
    color: '#188295',
  },
  fixedElement: {
    position: 'sticky',
    bottom: 0,
    background: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  location: {
    marginBottom: '1rem',
  },
}))

export class UsageRightsCreate extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      isProjectId: true,
      isInternalUsages: false,
      durationselected: 'Days',
      rightsHolderTypes: [],
      selectedRightsHolderType: '',
      rightsHolderNames: [],
      selectedRightsHolderName: '',
      channels: [],
      projects: [],
      terms: [],
      selectedTerm: '',
      territories: [],
      isCreateOption: false,
      newHolderName: '',
      isOthers: false,
      projectIDs: [],
      invalidProjectIDs: [],
      isCheckedFirstUseDate: 'firstusedate',
      projectDescription: '',
      selectedDate: new Date(),
      isWorldwide: false,
      isUnlimited: false,
      isOptionForRenewal: false,
      renewalNotes: '',
      internalRefNotes: '',
      otherChannelNotes: '',
      selectedChannels: [],
      durationselectedCount: 0,
      isDisableCreateRecord: true,
      isCreateRecordSuccess: false,
      drmId: '',
      newDrmId: '',
      isDetailsPage: false,
      buyer: '',
      recordCreatedDate: '',
      recordUpdatedDate: '',
      updatedBy: '',
      isEditPage: false,
      multiLevelChannels: [],
      customUsageRightsLanguage: '',
      customUsageRightsLanguageCopy: '',
      isCustomUsageRightsLanguage: false,
      selectedBuyer: '',
      isAddNewBuyer: false,
      newBuyerName: '',
      isValidEmail: null,
      buyerEmailsList: [],
      isEditable: true,
      //Location
      selectedUSAState: {
        state: '',
        country: '',
      },
    }
  }
  componentDidMount() {
    this.getUsageRightSummary()
  }

  componentDidUpdate(nextProps, nextState) {
    if (
      this.state.invalidProjectIDs.length !== nextState.invalidProjectIDs.length
    ) {
      return true
    }
    return false
  }

  checkDetailsPage = () => {
    const pathName = window.location.pathname
    const stringArr = pathName.split('/')
    let identifier = ''
    let idDRM = ''
    if (stringArr.length > 3) {
      identifier = stringArr[2]
      idDRM = stringArr[3]
    }
    if (identifier === 'details' && idDRM !== '') {
      this.props.getUsageRightsOldData(
        idDRM,
        (response) => {
          if (response.length > 0) {
            this.setState(
              {
                isDetailsPage: true,
                multiLevelChannels: convertIntoRequiredChannel(
                  removeDifferences(
                    response[0].channels && response[0].channels.length > 0
                      ? [...response[0].channels]
                      : [],
                  ),
                ),
              },
              () => {
                this.setDetailsToForm(response[0])
              },
            )
          }
        },
        () => {
          this.setState({ isDetailsPage: true })
        },
      )
    } else {
      this.setState({
        multiLevelChannels: convertIntoRequiredChannel(this.state.channels),
      })
    }
  }

  sortItems = (items) =>
    items.sort(function (item1, item2) {
      if (item1.toLowerCase() < item2.toLowerCase()) {
        return -1
      }
      if (item1.toLowerCase() > item2.toLowerCase()) {
        return 1
      }
      return 0
    })

  getUsageRightSummary = () => {
    this.props.getUsageRightsSummary(
      (data) => {
        const {
          rights_holder_types: holderTypes = [],
          rights_holder_names: holderName = [],
          channels = [],
          projects = [],
          terms = [],
          territories = [],
          buyer_emails: buyerEmails = [],
        } = data
        this.setState(
          {
            loading: false,
            rightsHolderTypes: this.sortItems(holderTypes),
            rightsHolderNames: this.sortItems(holderName),
            channels: channels,
            projects: projects,
            terms: this.sortItems(terms),
            territories: territories,
            buyerEmailsList: this.sortItems(buyerEmails),
          },
          () => {
            this.checkDetailsPage()
          },
        )
      },
      () => {},
    )
  }

  handleProjectID = () => {
    const { isProjectId } = this.state
    this.setState({ isProjectId: !isProjectId })
  }

  handleClickOpen = () => {
    this.setState({ isCreateOption: true })
  }

  handleClickAddNewBuyer = () => {
    this.setState({ isAddNewBuyer: true })
  }

  handleClose = () => {
    this.setState({ isCreateOption: false })
  }

  handleCloseAddNewBuyer = () => {
    this.setState({ isAddNewBuyer: false, isValidEmail: null })
  }

  handleCreateOption = () => {
    const { newHolderName = '', rightsHolderTypes = [] } = this.state
    const tempRightsHolderType = [...rightsHolderTypes]
    this.props.addNewOptionUsageRightsType(
      this.state.newHolderName,
      (response) => {
        const holderType = response.split(':')[1].trim()
        tempRightsHolderType.push(holderType)
        this.setState({
          selectedRightsHolderType: newHolderName,
          isCreateOption: false,
          newHolderName: '',
          rightsHolderTypes: this.sortItems(tempRightsHolderType),
        })
      },
      () => {
        this.setState({ isCreateOption: false, newHolderName: '' })
      },
    )
  }

  handleAddBuyerToDB = () => {
    const { newBuyerName = '' } = this.state
    const isValid = this.validateEmail(newBuyerName)
    this.setState({ isValidEmail: isValid })
    if (isValid) {
      this.props.addNewBuyer(
        newBuyerName,
        (response) => {
          this.setState({
            selectedBuyer: newBuyerName,
            isAddNewBuyer: false,
            newBuyerName: '',
            isValidEmail: isValid,
            buyerEmailsList: this.sortItems(response),
          })
        },
        () => {
          this.setState({
            isAddNewBuyer: false,
            newBuyerName: '',
            isValidEmail: isValid,
          })
        },
      )
    } else {
      this.setState({ isValidEmail: isValid })
    }
  }

  validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  checkProjectIdShouldPresent = () => {
    const {
      projects = [],
      projectIDs = [],
      invalidProjectIDs = [],
    } = this.state
    const index = projectIDs ? projectIDs.length - 1 : 0
    const idOfProject = projectIDs[index]
    const result = projects.filter((entry) => entry.project_id === idOfProject)
    if (result.length === 0) {
      this.setState({
        invalidProjectIDs: invalidProjectIDs.concat(idOfProject),
      })
    }
  }

  handleDeleteProjectId = (projectIdList = []) => {
    const { invalidProjectIDs = [] } = this.state
    if (projectIdList.length === 0) {
      this.setState({ projectIDs: projectIdList, invalidProjectIDs: [] })
    } else {
      this.setState({ projectIDs: projectIdList }, () => {
        let index = -1
        invalidProjectIDs.forEach((element, i) => {
          if (projectIdList.indexOf(element) === -1) {
            index = i
          }
        })
        if (index > -1) {
          let newArr = [...invalidProjectIDs]
          newArr.splice(index, 1)
          this.setState({ invalidProjectIDs: newArr })
        }
      })
    }
  }

  handleDateChange = (date) => {
    this.setState({ selectedDate: date })
  }

  handleProjDescription = (event) => {
    this.setState({ projectDescription: event.target.value })
  }

  handleRenewalNotes = (event) => {
    this.setState({ renewalNotes: event.target.value })
  }

  handleInternalRefNotes = (event) => {
    this.setState({ internalRefNotes: event.target.value })
  }

  handleSelectedTerm = (event) => {
    const { multiLevelChannels = [] } = this.state
    let tempChannels = [...multiLevelChannels]
    const term = event.target.value
    if (term === 'Work for Hire/Total Buy Out') {
      tempChannels[0].isChecked = true
      tempChannels[0].checkStatus = 'FULL'
      this.setState({
        selectedTerm: term,
        isWorldwide: true,
        isUnlimited: true,
        isInternalUsages: false,
        selectedChannels: ['Any and All Media'],
      })
      this.setState({
        multiLevelChannels: tempChannels,
        selectedTerm: term,
        isInternalUsages: false,
      })
    } else {
      this.setState({
        selectedTerm: term,
        isWorldwide: false,
        selectedChannels: [],
        multiLevelChannels: resetChannels(tempChannels),
      })
    }
  }

  setIndeterminateToChannel = (
    channels = [],
    listChannel = [],
    isDetails = false,
  ) => {
    let tempChannels = [...channels]
    if (isDetails) {
      for (let i = 0; i < tempChannels.length; i++) {
        tempChannels[i].isChecked = listChannel[i].selected
        if (
          tempChannels[i].subchannel &&
          tempChannels[i].subchannel.length > 0
        ) {
          tempChannels[i].subchannel = this.setIndeterminateToChannel(
            tempChannels[i].subchannel,
            listChannel[i].subchannel,
            isDetails,
          )
          tempChannels[i].checkStatus = listChannel[i].selected
            ? 'FULL'
            : this.lookChildNode(tempChannels[i].subchannel)
        } else {
          tempChannels[i].checkStatus = listChannel[i].selected
            ? 'FULL'
            : 'NONE'
        }
      }
    }
    return tempChannels
  }

  handleParentCheckBox = (pNode, channelList = []) => {
    const { multiLevelChannels = [] } = this.state
    let tempChannels =
      channelList && channelList.length > 0
        ? channelList
        : [...multiLevelChannels]
    if (pNode !== null && pNode !== undefined) {
      let gPNode
      for (let i = 0; i < tempChannels.length; i++) {
        if (pNode === tempChannels[i].label) {
          gPNode = tempChannels[i].parentNode
          if (
            tempChannels[i].subchannel &&
            tempChannels[i].subchannel.length > 0
          ) {
            tempChannels[i].checkStatus = this.lookChildNode(
              tempChannels[i].subchannel,
            ) //"INDETERMINATE" or "FULL" or "NONE"
            tempChannels[i].isChecked =
              tempChannels[i].checkStatus === 'FULL' ? true : false
          }
          break
        }
        if (
          tempChannels[i].subchannel &&
          tempChannels[i].subchannel.length > 0
        ) {
          this.handleParentCheckBox(pNode, tempChannels[i].subchannel)
        }
      }
      if (gPNode !== null && gPNode !== undefined) {
        this.handleParentCheckBox(gPNode)
      }
    }
    this.setState({ multiLevelChannels: tempChannels })
  }

  lookChildNode = (subchannel) => {
    let status = subchannel[0].checkStatus
    if (status !== 'INDETERMINATE') {
      for (let i = 1; i < subchannel.length; i++) {
        if (status !== subchannel[i].checkStatus) {
          status = 'INDETERMINATE'
          break
        }
      }
    }
    return status
  }

  setSelectedChannel = () => {
    const { multiLevelChannels = [] } = this.state
    let selectedChannelList = []
    let tempArr = []
    for (let i = 0; i < multiLevelChannels.length; i++) {
      if (multiLevelChannels[i].isChecked) {
        selectedChannelList.push(multiLevelChannels[i].label)
      } else {
        if (
          multiLevelChannels[i].subchannel &&
          multiLevelChannels[i].subchannel.length > 0
        ) {
          tempArr = this.identifyRestOfChannelSelected(
            multiLevelChannels[i].subchannel,
          ).concat(tempArr)
        }
      }
    }
    this.setState({ selectedChannels: selectedChannelList.concat(tempArr) })
  }

  identifyRestOfChannelSelected = (channel) => {
    let selectedChannelList = []
    let tempArr = []
    for (let i = 0; i < channel.length; i++) {
      if (channel[i].isChecked) {
        selectedChannelList.push(channel[i].label)
      } else {
        if (channel[i].subchannel && channel[i].subchannel.length > 0) {
          tempArr = this.identifyRestOfChannelSelected(
            channel[i].subchannel,
          ).concat(tempArr)
        }
      }
    }
    return selectedChannelList.concat(tempArr)
  }

  handleCheckBoxEvent = (event, channel) => {
    const { multiLevelChannels = [] } = this.state
    let tempChannels = [...multiLevelChannels]
    const isChecked = event.target.checked
    let tempList = [...this.state.selectedChannels]
    for (let i = 0; i < tempChannels.length; i++) {
      if (channel.label === tempChannels[i].label) {
        tempChannels[i].isChecked = !channel.isChecked
        tempChannels[i].checkStatus =
          channel.checkStatus === 'INDETERMINATE'
            ? 'FULL'
            : channel.checkStatus === 'NONE'
              ? 'FULL'
              : 'NONE'
        if (
          tempChannels[i].subchannel &&
          tempChannels[i].subchannel.length > 0
        ) {
          this.handleClickOnCheckBox(tempChannels[i].subchannel, true, channel)
        }
      }
      if (tempChannels[i].subchannel && tempChannels[i].subchannel.length > 0) {
        this.handleClickOnCheckBox(tempChannels[i].subchannel, false, channel)
      }
    }
    this.setState({ multiLevelChannels: tempChannels })
  }

  handleClickOnCheckBox = (channel = [], isCheck = false, channelObj = {}) => {
    if (isCheck) {
      for (let i = 0; i < channel.length; i++) {
        channel[i].isChecked = channelObj.isChecked
        channel[i].checkStatus =
          channelObj.checkStatus === 'INDETERMINATE'
            ? 'FULL'
            : channelObj.checkStatus
        if (channel[i].subchannel && channel[i].subchannel.length > 0) {
          this.handleClickOnCheckBox(channel[i].subchannel, true, channelObj)
        }
      }
    } else {
      for (let i = 0; i < channel.length; i++) {
        if (channelObj.label === channel[i].label) {
          channel[i].isChecked = !channelObj.isChecked
          channel[i].checkStatus =
            channelObj.checkStatus === 'INDETERMINATE'
              ? 'FULL'
              : channelObj.checkStatus === 'NONE'
                ? 'FULL'
                : 'NONE'
          if (channel[i].subchannel && channel[i].subchannel.length > 0) {
            this.handleClickOnCheckBox(channel[i].subchannel, true, channelObj)
          }
        }
        if (channel[i].subchannel && channel[i].subchannel.length > 0) {
          this.handleClickOnCheckBox(channel[i].subchannel, false, channelObj)
        }
      }
    }
  }

  handleExpensionCheckBox = (event, channel) => {
    const { multiLevelChannels = [] } = this.state
    let tempChannels = [...multiLevelChannels]
    for (let i = 0; i < tempChannels.length; i++) {
      if (channel.label === tempChannels[i].label) {
        tempChannels[i].isOpen = !channel.isOpen
      } else {
        if (
          tempChannels[i].subchannel &&
          tempChannels[i].subchannel.length > 0
        ) {
          this.handleCheckBoxExpension(tempChannels[i].subchannel, channel)
        }
      }
    }
    this.setState({ multiLevelChannels: tempChannels })
  }

  handleCheckBoxExpension = (channel, channelObj) => {
    for (let index = 0; index < channel.length; index++) {
      if (channelObj.label === channel[index].label) {
        channel[index].isOpen = !channelObj.isOpen
      } else {
        if (channel[index].subchannel && channel[index].subchannel.length > 0) {
          this.handleCheckBoxExpension(channel[index].subchannel, channelObj)
        }
      }
    }
  }

  setDetailsToForm = (data) => {
    const {
      project_description: proDescription = true,
      project_id: proId = [],
      recurring = true,
      first_use_time: firstUseTime = new Date().getTime(),
      holder_type: holderType = '',
      holder_name: holderName = '',
      terms = '',
      buyer = '',
      internal = false,
      other_channel: otherChannel = false,
      other_channel_name: otherChannelName = '',
      usage_rights_id: usageRightsId = '',
      new_usage_rights_id: newUsageRightsId = '',
      territory = 'North America',
      renewable = false,
      renewal_notes: renewalNotes = '',
      duration = 'Unlimited',
      notes = '',
      creation_time: creationTime = new Date().getTime(),
      updated_time: updatedTime = new Date().getTime(),
      updated_by: updatedBy = '',
      channels: listOfChannel = [],
      custom_usage_rights_language: customUsageRightsLanguage = '',
      custom_usage_rights: isCustomUsageRights = false,
      state: state = '',
      country: country = '',
      editable = true,
    } = data
  
    const { multiLevelChannels } = this.state
    let tempMultiLevelChannels = [...multiLevelChannels]
    tempMultiLevelChannels = this.setIndeterminateToChannel(
      tempMultiLevelChannels,
      listOfChannel,
      true,
    )
    this.setSelectedChannel()

    this.setState({
      projectDescription: proDescription || '',
      isProjectId: !proDescription,
      projectIDs: proId,
      isCheckedFirstUseDate: !recurring ? 'firstusedate' : 'itemlaunchdate',
      selectedDate: recurring ? new Date() : new Date(firstUseTime),
      selectedRightsHolderType: holderType,
      selectedRightsHolderName: holderName,
      selectedTerm: terms,
      buyer: buyer,
      isInternalUsages: internal,
      isOthers: otherChannel,
      otherChannelNotes: otherChannelName,
      drmId: usageRightsId,
      newDrmId: newUsageRightsId,
      isWorldwide: territory.toLowerCase() === 'north america' ? false : true,
      isOptionForRenewal: renewable,
      renewalNotes: renewalNotes,
      isUnlimited: duration.toLowerCase() === 'unlimited' ? true : false,
      internalRefNotes: notes,
      durationselectedCount:
        duration.toLowerCase() !== 'unlimited' ? divideString(duration, 0) : '',
      durationselected:
        duration.toLowerCase() !== 'unlimited'
          ? divideString(duration, 1)
          : 'Days',
      recordCreatedDate: formatUsageRightsDate(creationTime),
      recordUpdatedDate: formatUsageRightsDate(updatedTime),
      multiLevelChannels: tempMultiLevelChannels,
      creationTime,
      updatedBy,
      customUsageRightsLanguage,
      customUsageRightsLanguageCopy: customUsageRightsLanguage,
      isCustomUsageRightsLanguage: isCustomUsageRights,
      selectedBuyer: buyer,
      selectedUSAState: {
        state: state ?? '',
        country: country ?? '',
      },
      isEditable: editable,
    })
  }

  handleCreateRecord = () => {
    const { userEmail = '', createNewRecordUsageRights = () => {} } = this.props
    const {
      isProjectId = true,
      isInternalUsages = false,
      durationselected = 'Days',
      selectedRightsHolderType = '',
      selectedRightsHolderName = '',
      selectedTerm = '',
      isOthers = false,
      projectIDs = [],
      isCheckedFirstUseDate = 'firstusedate',
      projectDescription = '',
      selectedDate = new Date(),
      isWorldwide = false,
      isUnlimited = false,
      isOptionForRenewal = false,
      renewalNotes = '',
      internalRefNotes = '',
      otherChannelNotes = '',
      durationselectedCount = 0,
      drmId = '',
      newDrmId = '',
      creationTime = new Date().getTime(),
      buyer = '',
      isEditPage = false,
      multiLevelChannels = [],
      customUsageRightsLanguage = '',
      customUsageRightsLanguageCopy = '',
      isCustomUsageRightsLanguage = false,
      selectedBuyer = '',
      selectedUSAState = {},
    } = this.state
    const tempChannel = channelConvertion(multiLevelChannels)
    const firstUseDate = isMoment(selectedDate)
      ? new Date(selectedDate.utc().format())
      : selectedDate
    let tempExpiryTime = new Date(firstUseDate.getTime())
    if (durationselected === 'Days') {
      tempExpiryTime.setDate(
        tempExpiryTime.getDate() + parseInt(durationselectedCount),
      )
    } else if (durationselected === 'Weeks') {
      tempExpiryTime.setDate(
        tempExpiryTime.getDate() + parseInt(durationselectedCount * 7),
      )
    } else if (durationselected === 'Months') {
      tempExpiryTime.setMonth(
        tempExpiryTime.getMonth() + parseInt(durationselectedCount),
      )
    } else if (durationselected === 'Years') {
      tempExpiryTime.setFullYear(
        tempExpiryTime.getFullYear() + parseInt(durationselectedCount),
      )
    }
    let params = {
      projects_assigned: projectIDs,
      project_description: projectDescription,
      first_use_time:
        isCheckedFirstUseDate === 'itemlaunchdate'
          ? null
          : firstUseDate.getTime(),
      holder_type: selectedRightsHolderType,
      holder_name: selectedRightsHolderName,
      channels: tempChannel,
      term_name: selectedTerm,
      creation_time: new Date().getTime(),
      created_by: selectedBuyer !== '' ? selectedBuyer : userEmail,
      updated_by: userEmail,
      updated_time: new Date().getTime(),
      territory: isWorldwide ? 'Worldwide' : 'North America',
      notes: internalRefNotes,
      expiry_time: isUnlimited
        ? new Date('9999-12-31T23:59:59Z').getTime()
        : tempExpiryTime.getTime(),
      internal: isInternalUsages,
      other_channel: isOthers,
      other_channel_name: isOthers ? otherChannelNotes : '',
      duration: isUnlimited
        ? 'Unlimited'
        : durationselectedCount + ' ' + durationselected,
      recurring: isCheckedFirstUseDate === 'firstusedate' ? false : true,
      renewable: isOptionForRenewal,
      renewal_notes: isOptionForRenewal ? renewalNotes : '',
      usage_rights_language: this.createUsageRightsLanguage(),
      custom_usage_rights_language: isCustomUsageRightsLanguage
        ? customUsageRightsLanguage
        : customUsageRightsLanguageCopy,
      custom_usage_rights: !isCustomUsageRightsLanguage
        ? false
        : customUsageRightsLanguage !== ''
          ? true
          : false,

      country:
        selectedUSAState?.country === 'N/A'
          ? null
          : selectedUSAState?.country || '',
      state:
        selectedUSAState?.state === 'N/A'
          ? null
          : selectedUSAState?.state || '',
    }

    if (isEditPage) {
      params.usage_rights_id = drmId
      params.new_usage_rights_id = newDrmId
      params.created_by = selectedBuyer !== '' ? selectedBuyer : buyer
      params.creation_time = creationTime
      params.updated_by = userEmail
      params.updated_time = new Date().getTime()
    }
    createNewRecordUsageRights(
      params,
      (response) => {
        const {
          usage_rights_id: usageRightsId = '',
          new_usage_rights_id = '',
        } = response
        this.setState({
          isCreateRecordSuccess: true,
          drmId: usageRightsId,
          newDrmId: new_usage_rights_id,
        })
      },
      () => {},
    )
  }

  createCustomUsageRightsLanguage = (event) => {
    this.setState({ customUsageRightsLanguage: event.target.value })
  }

  emptyCustomUsageRightsLanguage = (toggle) => {
    this.setState({ isCustomUsageRightsLanguage: toggle })
  }

  createUsageRightsLanguage = () => {
    const {
      drmId = '',
      newDrmId = '',
      selectedTerm = '',
      isUnlimited = false,
      selectedRightsHolderType = '',
      selectedRightsHolderName = '',
      isWorldwide = false,
      isInternalUsages = false,
      selectedChannels = [],
      isOthers = false,
      otherChannelNotes = '',
      isCheckedFirstUseDate = 'firstusedate',
      durationselectedCount = '',
      durationselected = '',
      selectedDate = moment().format('MMM Do YYYY h:mm:ss a'),
      isOptionForRenewal = false,
      renewalNotes = '',
    } = this.state
    const termCmp = 'Work for Hire/Total Buy Out'
    const finalDrmId = newDrmId ? newDrmId : drmId

    if (selectedTerm !== termCmp && !isUnlimited) {
      return (
        (finalDrmId ? finalDrmId + ' - ' : '') +
        (selectedRightsHolderType !== 'Talent'
          ? selectedRightsHolderName
          : 'Talent') +
        ' - ' +
        selectedTerm +
        ' for exclusive ' +
        (isWorldwide ? 'Worldwide' : 'North America') +
        ' usage by Target in ' +
        (isInternalUsages ? 'Internal Use Only' : '') +
        (!isInternalUsages && selectedChannels.length > 0
          ? isOthers
            ? convertToStringForm(selectedChannels, '&')
            : convertToStringForm(selectedChannels)
          : '') +
        (!isInternalUsages && isOthers ? otherChannelNotes : '') +
        (isCheckedFirstUseDate === 'firstusedate'
          ? ' through ' +
            formatExpirationDate(
              selectedDate,
              durationselected,
              durationselectedCount,
            )
          : ' for ' +
            durationselectedCount +
            ' ' +
            durationselected +
            ' starting with the first use of the asset. ') +
        (!isInternalUsages
          ? 'Target retains self-promotion and internal usage rights forever. Any assets licensed and used online must be published prior to the ' +
            (isCheckedFirstUseDate === 'firstusedate' && selectedDate
              ? formatExpirationDate(
                  selectedDate,
                  durationselected,
                  durationselectedCount,
                )
              : 'expiration date') +
            ', but Target does not have an obligation to remove once published. Target requires first option of reuse upon expiration.'
          : '') +
        (!isUnlimited && isOptionForRenewal
          ? ' Target requires an option for renewal: ' + renewalNotes
          : '')
      )
    } else {
      return (
        (finalDrmId ? finalDrmId + ' - ' : '') +
        (selectedRightsHolderType !== 'Talent'
          ? selectedRightsHolderName
          : 'Talent - ') +
        selectedTerm +
        ' for exclusive Unlimited ' +
        (isWorldwide ? 'Worldwide' : 'North America') +
        ' usage by Target in ' +
        (isInternalUsages ? 'Internal Use Only' : '') +
        (!isInternalUsages && selectedChannels.length > 0
          ? isOthers
            ? convertToStringForm(selectedChannels, '&')
            : convertToStringForm(selectedChannels)
          : '') +
        (!isInternalUsages && isOthers ? otherChannelNotes : '') +
        ' . Target retains self-promotion and internal usage rights forever. ' +
        (!isUnlimited && isOptionForRenewal
          ? 'Target requires an option for renewal: ' + renewalNotes
          : '')
      )
    }
  }

  handleDisableCreateRecord = () => {
    const {
      isInternalUsages = false,
      selectedRightsHolderType = '',
      selectedRightsHolderName = '',
      isOthers = false,
      projectIDs = [],
      projectDescription = '',
      isUnlimited = false,
      durationselectedCount = 0,
      selectedChannels = [],
      selectedTerm = '',
      selectedUSAState,
    } = this.state

    const requiresValidLocation = [
      'Talent',
      'Talent - Unrecognizable',
      'VO Talent',
    ].includes(selectedRightsHolderType)
      ? selectedUSAState?.state !== '' && selectedUSAState?.country !== ''
      : true

    if (
      (projectDescription !== '' || (projectIDs && projectIDs.length > 0)) &&
      (selectedRightsHolderType === 'Talent' ||
        (selectedRightsHolderType !== '' && selectedRightsHolderName !== '')) &&
      (isInternalUsages || selectedChannels.length > 0 || isOthers) &&
      (isUnlimited || durationselectedCount !== '') &&
      selectedTerm !== '' &&
      requiresValidLocation
    ) {
      return false
    }
    return true
  }

  handleGotIt = () => {
    const { router = {} } = this.props
    this.setState(
      { isCreateRecordSuccess: false, drmId: '', newDrmId: '' },
      () => {
        router.navigate('/usageRights')
      },
    )
  }

  handleEditRecord = () => {
    this.setState({ isDetailsPage: false, isEditPage: true })
  }

  handleSwitchToggleNew = (event) => {
    this.setState({ isCheckedFirstUseDate: event.target.value })
  }

  handleBlurProjectId = (event) => {
    if (event.target.value) {
      this.settingProjectState(event.target.value)
    }
  }

  settingProjectState = (val) => {
    this.setState({ projectIDs: this.state.projectIDs.concat(val) })
  }

  handleKeyDownProjectId = (event) => {
    if (event.keyCode === 13 && event.target.value) {
      this.settingProjectState(event.target.value)
    }
  }

  handleHolderType = (event) => {
    if (event.target.value === 'Talent') {
      this.setState({
        selectedRightsHolderType: event.target.value,
        selectedRightsHolderName: '',
      })
    } else {
      this.setState({ selectedRightsHolderType: event.target.value })
    }
  }

  handleInternalUsagesCheckBox = () => {
    const { isInternalUsages = true } = this.state
    this.setState({ isInternalUsages: !isInternalUsages, isOthers: false })
  }

  handleOtherCheckbox = () => {
    const { isOthers = false } = this.state
    this.setState({ isOthers: !isOthers })
  }

  handleOtherChannelNotes = (event) => {
    this.setState({ otherChannelNotes: event.target.value })
  }

  handleDuration = (event) => {
    this.setState({ durationselected: event.target.value })
  }

  handleUnlimitedCheckbox = () => {
    const { isUnlimited = false } = this.state
    this.setState({
      isUnlimited: !isUnlimited,
      isWorldwide: true,
      isOptionForRenewal: false,
    })
  }

  handleOptionRenewalCheckbox = () => {
    const { isOptionForRenewal = false } = this.state
    this.setState({ isOptionForRenewal: !isOptionForRenewal })
  }

  handleNewHolderName = (event) => {
    this.setState({ newHolderName: event.target.value })
  }

  handleNewBuyer = (event) => {
    this.setState({ newBuyerName: event.target.value, isValidEmail: null })
  }

  changeIntoDesiredArray = (inputArr) => {
    let outputArr = []
    inputArr.forEach((element) => {
      outputArr.push({
        title: element,
      })
    })
    return outputArr
  }

  setSelectedHolderName = (name) => {
    this.setState({ selectedRightsHolderName: name })
  }

  addNewOptionToHolderName = (holderName, callBack) => {
    const { addNewOptionUsageRightsName = () => {} } = this.props
    addNewOptionUsageRightsName(
      holderName,
      (response) => {
        this.setState({
          selectedRightsHolderName: holderName,
          rightsHolderNames: response.sort(),
        })
        callBack()
      },
      () => {
        this.setState({ selectedRightsHolderName: '', rightsHolderNames: [] })
        callBack()
      },
    )
  }

  handleBuyerSelection = (event) => {
    this.setState({ selectedBuyer: event.target.value })
  }

  renderFirstUseDate = () => {
    const {
      selectedDate = new Date(),
      isCheckedFirstUseDate = 'firstusedate',
      isDetailsPage = false,
    } = this.state
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          disableToolbar
          data-cy="firstUseDatePicker"
          variant="inline"
          inputFormat="MM/dd/yyyy"
          margin="normal"
          id="select-first-use-date"
          value={selectedDate}
          onChange={this.handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          disabled={isCheckedFirstUseDate !== 'firstusedate' || isDetailsPage}
          renderInput={(props) => (
            <TextField {...props} label="Select First Use Date" />
          )}
        />
      </LocalizationProvider>
    )
  }

  handleLocationChange = (type) => (event) => {
    const value = event.target.value

    this.setState((prevState) => {
      const updatedState = { ...prevState.selectedUSAState, [type]: value }

      if (type === 'country' && value === 'International') {
        updatedState.state = 'N/A'
      }

      if (type === 'state') {
        updatedState.country =
          value === 'N/A' ? 'International' : 'United States'
      }

      return { selectedUSAState: updatedState }
    })
  }

  render() {
    const {
      classes = {},
      userEmail = '',
      env = {},
      auth: { isAuthorized = () => {} },
      isAdmin = false,
    } = this.props
    const {
      isInternalUsages = true,
      durationselected = 'Days',
      loading = true,
      rightsHolderTypes = [],
      selectedRightsHolderType = '',
      selectedRightsHolderName = '',
      rightsHolderNames = [],
      selectedTerm = '',
      terms = [],
      isCreateOption = false,
      isOthers = false,
      projectIDs = [],
      invalidProjectIDs = [],
      isCheckedFirstUseDate = 'firstusedate',
      projectDescription = '',
      isWorldwide = false,
      isUnlimited = false,
      isOptionForRenewal = false,
      renewalNotes = '',
      internalRefNotes = '',
      otherChannelNotes = '',
      durationselectedCount = 0,
      newDrmId = '',
      drmId = '',
      isCreateRecordSuccess = false,
      isDetailsPage = false,
      isEditPage = false,
      multiLevelChannels = [],
      selectedBuyer = '',
      isAddNewBuyer = false,
      isValidEmail = null,
      buyerEmailsList = [],
      isEditable = true,
      selectedUSAState = '',
    } = this.state
    const {
      REACT_APP_ROLES_CONFIG: { usageRightsTCIN = null },
    } = env

    const isAuthorizedTCIN = isAuthorized(usageRightsTCIN)
    const breadcrumbs = isDetailsPage
      ? [
          { path: '/usageRights', title: 'Usage Rights' },
          {
            path: `/usageRights/details/${newDrmId ? newDrmId : drmId}`,
            title: 'Detail',
          },
        ]
      : [
          { path: '/usageRights', title: 'Usage Rights' },
          {
            path: isEditPage
              ? `/usageRights/edit/${newDrmId ? newDrmId : drmId}`
              : '/usageRights/create',
            title: isEditPage ? 'Edit' : 'Create',
          },
        ]
    const breadcrumbsLoading = [{ path: '/usageRights', title: 'Usage Rights' }]
    const newHeaderTitle = loading
      ? generateBreadcrumb(breadcrumbsLoading)
      : generateBreadcrumb(breadcrumbs)
    const messageForProjectIds = projectIDs
      ? projectIDs.length > 1
        ? `${projectIDs.length} project ids entered`
        : `${projectIDs.length} project id entered`
      : ''
    const headerTextToBeDisplayed = isDetailsPage
      ? 'Usage Rights Record'
      : isEditPage
        ? 'Edit Usage Rights Record'
        : 'New Usage Rights Record'

    const { state, country } = selectedUSAState
    return (
      <div className={classes.root}>
        <HeaderTitle title={newHeaderTitle} icon="Usage Rights" />
        {loading ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <div style={{ position: 'relative', paddingBottom: '24px' }}>
                  <div className={classes.createHeader}>
                    {headerTextToBeDisplayed}
                  </div>
                  {isDetailsPage && isEditable && (
                    <div
                      style={{
                        display: 'inline',
                        position: 'absolute',
                        right: 0,
                      }}
                    >
                      <Button
                        data-cy="detailsEditButton"
                        style={{
                          color: '#0D46A0',
                          border: '1px solid #0D46A0',
                        }}
                        variant="outlined"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={this.handleEditRecord}
                      >
                        Edit Record
                      </Button>
                    </div>
                  )}
                </div>
                <div>
                  <TextField
                    id="projectDes"
                    label="Project Description"
                    variant="outlined"
                    value={projectDescription}
                    onChange={this.handleProjDescription}
                    disabled={isDetailsPage}
                    inputProps={{ 'data-cy': 'projectNameTextField' }}
                    fullWidth
                    style={{ margin: '32px 0 16px 0' }}
                  />
                </div>
                <div>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    open={false}
                    options={[]}
                    value={projectIDs}
                    onChange={(e, newval) => {
                      this.handleDeleteProjectId(newval)
                    }}
                    disabled={isDetailsPage}
                    popupIcon={null}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option}
                          {...getTagProps({ index })}
                          color={
                            invalidProjectIDs.indexOf(option) !== -1
                              ? 'secondary'
                              : 'default'
                          }
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Project ID (optional)"
                        placeholder="Project ID (optional)"
                        onBlur={this.handleBlurProjectId}
                        onKeyDown={this.handleKeyDownProjectId}
                        data-cy="projectIdTextField"
                        helperText={
                          !isDetailsPage && projectIDs && projectIDs.length > 0
                            ? messageForProjectIds
                            : null
                        }
                      />
                    )}
                  />
                </div>
                <TextField
                  data-cy="buyerName"
                  id="buyerName"
                  select
                  label="Buyer Name"
                  value={selectedBuyer}
                  onChange={this.handleBuyerSelection}
                  variant="outlined"
                  fullWidth
                  style={{ margin: '16px 0px 0px' }}
                  disabled={isDetailsPage}
                >
                  <div
                    style={{
                      border: '1px solid #EFEFEF',
                      width: '100%',
                    }}
                  ></div>
                  {buyerEmailsList.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                  <MenuItem
                    onClick={this.handleClickAddNewBuyer}
                    className={classes.fixedElement}
                  >
                    <AddIcon className={classes.primaryColor} />
                    <span className={classes.primaryColor}>Add New Buyer</span>
                  </MenuItem>
                </TextField>
                <TextField
                  data-cy="rightsHolderType"
                  id="rightHolderType"
                  select
                  label="Rights Holder Type"
                  value={selectedRightsHolderType}
                  required
                  onChange={this.handleHolderType}
                  variant="outlined"
                  fullWidth
                  style={{ margin: '16px 0 16px 0' }}
                  disabled={isDetailsPage}
                >
                  <div
                    style={{
                      border: '1px solid #EFEFEF',
                      width: '100%',
                    }}
                  ></div>
                  {rightsHolderTypes.map((option) => (
                    <MenuItem key={option} value={option} data-cy={option}>
                      {option}
                    </MenuItem>
                  ))}
                  <MenuItem
                    className={classes.fixedElement}
                    onClick={this.handleClickOpen}
                  >
                    <AddIcon className={classes.primaryColor} />
                    <span className={classes.primaryColor}>
                      Create New Option
                    </span>
                  </MenuItem>
                </TextField>
                {(selectedRightsHolderType === 'VO Talent' ||
                  selectedRightsHolderType === 'Talent' ||
                  selectedRightsHolderType === 'Talent - Unrecognizable') && (
                  <Grid container spacing={1} className={classes.location}>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel>State</InputLabel>
                        <Select
                          value={state}
                          onChange={this.handleLocationChange('state')}
                          label="Location"
                          required
                          disabled={isDetailsPage}
                        >
                          {usaStatesInfo.map((state) => (
                            <MenuItem
                              key={state}
                              value={state}
                              disabled={
                                this.state.selectedUSAState === 'International'
                              }
                            >
                              {state}
                            </MenuItem>
                          ))}
                          <MenuItem value="N/A">N/A</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Country Selector */}
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel>Country</InputLabel>
                        <Select
                          value={country}
                          onChange={this.handleLocationChange('country')}
                          label="Country"
                          required
                          disabled={isDetailsPage}
                        >
                          {['United States', 'International'].map((country) => (
                            <MenuItem key={country} value={country}>
                              {country}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                <AutoCompleteHolderName
                  selectedRightsHolderObj={{ title: selectedRightsHolderName }}
                  optionsArray={this.changeIntoDesiredArray(rightsHolderNames)}
                  disabled={
                    selectedRightsHolderType === 'Talent' || isDetailsPage
                  }
                  setSelectedHolderName={(name) => {
                    this.setSelectedHolderName(name)
                  }}
                  callApiToSaveHolderName={(holderName, callBack) => {
                    this.addNewOptionToHolderName(holderName, callBack)
                  }}
                />

                <TextField
                  data-cy="terms"
                  id="terms"
                  select
                  label="Terms"
                  value={selectedTerm}
                  onChange={this.handleSelectedTerm}
                  variant="outlined"
                  fullWidth
                  required
                  style={{ margin: '16px 0 16px 0' }}
                  disabled={isDetailsPage}
                >
                  {terms.map((option) => (
                    <MenuItem key={option} value={option} data-cy={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <div style={{ color: 'black' }}>Available Channels</div>
                <FormGroup>
                  <ul style={{ listStyleType: 'none' }}>
                    <li style={{ listStyleType: 'none' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="isInternalUsages"
                            checked={isInternalUsages}
                            onChange={this.handleInternalUsagesCheckBox}
                            name="isInternalUsages"
                            disabled={
                              selectedTerm === 'Work for Hire/Total Buy Out' ||
                              isDetailsPage
                            }
                          />
                        }
                        label={INTERNAL_USAGE_CHANNEL_LABEL}
                      />
                    </li>

                    {/* New Implementation Starts */}
                    <MultiLevelCheckBox
                      isInternalUsages={isInternalUsages}
                      selectedTerm={selectedTerm}
                      channels={multiLevelChannels}
                      isDetailsPage={isDetailsPage}
                      handleParentCheckBox={this.handleParentCheckBox}
                      handleCheckBoxEvent={this.handleCheckBoxEvent}
                      handleExpensionCheckBox={this.handleExpensionCheckBox}
                      setSelectedChannel={this.setSelectedChannel}
                    />
                    {/* New Implementation Ends*/}

                    <li style={{ listStyleType: 'none' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="isOthers"
                            checked={isOthers}
                            onChange={this.handleOtherCheckbox}
                            name="isOthers"
                            disabled={isDetailsPage || isInternalUsages}
                          />
                        }
                        label={OTHER_CHANNEL_LABEL}
                      />
                    </li>
                  </ul>
                  {isOthers && (
                    <TextField
                      variant="standard"
                      data-cy="otherChannelText"
                      id="otherChannel"
                      label="Other Channel"
                      helperText={`${otherChannelNotes.length}/200 Characters`}
                      fullWidth
                      margin="normal"
                      value={otherChannelNotes}
                      onChange={this.handleOtherChannelNotes}
                      error={otherChannelNotes.length > 200 ? true : false}
                      disabled={isDetailsPage}
                    />
                  )}
                </FormGroup>
                <div style={{ marginTop: '16px' }}>
                  <FormControl>
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      style={{ color: 'black' }}
                    >
                      First use date
                    </FormLabel>
                    {isAuthorizedTCIN ? (
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={isCheckedFirstUseDate}
                        onChange={this.handleSwitchToggleNew}
                        style={{ margin: '32px 0 16px 0' }}
                      >
                        <FormControlLabel
                          value="firstusedate"
                          control={<Radio />}
                          disabled={isDetailsPage}
                          label={this.renderFirstUseDate()}
                        />
                        <FormControlLabel
                          disabled={isDetailsPage}
                          value="itemlaunchdate"
                          control={<Radio />}
                          label="Item Launch Date"
                        />
                      </RadioGroup>
                    ) : (
                      <div style={{ margin: '32px 0 16px 0' }}>
                        {this.renderFirstUseDate()}
                      </div>
                    )}
                  </FormControl>
                </div>
                <div style={{ marginTop: '16px', color: 'black' }}>
                  Duration
                </div>
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <TextField
                    variant="standard"
                    data-cy="durationAmount"
                    id="id#"
                    placeholder="#"
                    size="small"
                    disabled={isDetailsPage || isUnlimited}
                    value={durationselectedCount}
                    onChange={(event) => {
                      this.setState({
                        durationselectedCount: event.target.value,
                      })
                    }}
                  />
                  <TextField
                    variant="standard"
                    data-cy="durationType"
                    id="duration"
                    select
                    value={durationselected}
                    disabled={isDetailsPage || isUnlimited}
                    onChange={this.handleDuration}
                    style={{ margin: '0px 8px' }}
                  >
                    {daySelection.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        data-cy={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-cy="isUnlimited"
                        checked={isUnlimited}
                        onChange={this.handleUnlimitedCheckbox}
                        name="isUnlimited"
                        disabled={
                          isDetailsPage ||
                          selectedTerm === 'Work for Hire/Total Buy Out'
                        }
                      />
                    }
                    label="Unlimited"
                    style={{ margin: '0px 8px' }}
                  />
                </div>
                <div style={{ marginTop: '16px', color: 'black' }}>
                  Available Territories (Print, Out of Home, TV Only)
                </div>
                <div
                  style={{
                    display: 'inline-flex',
                    textAlign: 'center',
                  }}
                >
                  <Paper
                    data-cy="northAmerica"
                    className={
                      !isWorldwide
                        ? classes.paperChipLeftSelected
                        : classes.paperChipLeft
                    }
                    onClick={
                      isDetailsPage ||
                      selectedTerm === 'Work for Hire/Total Buy Out'
                        ? () => {}
                        : () => {
                            this.setState({ isWorldwide: false })
                          }
                    }
                  >
                    North America
                  </Paper>
                  <Paper
                    data-cy="worldwide"
                    className={
                      isWorldwide
                        ? classes.paperChipRightSelected
                        : classes.paperChipRight
                    }
                    onClick={
                      isDetailsPage ||
                      selectedTerm === 'Work for Hire/Total Buy Out'
                        ? () => {}
                        : () => {
                            this.setState({ isWorldwide: true })
                          }
                    }
                  >
                    Worldwide
                  </Paper>
                </div>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-cy="isOptionForRenewal"
                      checked={isOptionForRenewal}
                      onChange={this.handleOptionRenewalCheckbox}
                      name="isOptionForRenewal"
                      disabled={
                        isDetailsPage ||
                        selectedTerm === 'Work for Hire/Total Buy Out' ||
                        isUnlimited
                      }
                    />
                  }
                  label="Option for Renewal"
                  style={{ display: 'block' }}
                />
                {isOptionForRenewal && !isUnlimited && (
                  <TextField
                    variant="standard"
                    data-cy="renewalText"
                    id="renewalText"
                    label="Renewal Notes (Cost for Renewal, Terms)"
                    helperText={`${renewalNotes.length}/500 Characters`}
                    fullWidth
                    margin="normal"
                    value={renewalNotes}
                    onChange={this.handleRenewalNotes}
                    error={renewalNotes.length > 500 ? true : false}
                    disabled={isDetailsPage}
                  />
                )}
                <TextField
                  variant="standard"
                  data-cy="notes"
                  id="notes"
                  label="Notes"
                  helperText="For Internal Reference"
                  fullWidth
                  margin="normal"
                  value={internalRefNotes}
                  onChange={this.handleInternalRefNotes}
                  disabled={isDetailsPage}
                />
                {!isDetailsPage && (
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      data-cy={isEditPage ? 'saveButton' : 'createButton'}
                      className={classes.buttonForSaveCreate}
                      color="primary"
                      variant="contained"
                      style={{ fontSize: '16px' }}
                      onClick={this.handleCreateRecord}
                      disabled={this.handleDisableCreateRecord()}
                    >
                      {isEditPage ? 'SAVE CHANGES' : 'CREATE RECORD'}
                    </Button>
                  </div>
                )}
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <UsageRightsLanguage
                userEmail={userEmail}
                {...this.state}
                {...this.props}
                createCustomUsageRightsLanguage={
                  this.createCustomUsageRightsLanguage
                }
                emptyCustomUsageRightsLanguage={
                  this.emptyCustomUsageRightsLanguage
                }
              />
            </Grid>
          </Grid>
        )}
        {isCreateOption && (
          <Dialog
            open={isCreateOption}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Create a new Rights Holder Type option?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                The option will be available for all users.
              </DialogContentText>
              <TextField
                variant="standard"
                autoFocus
                margin="dense"
                id="name"
                label="Enter Option"
                type="email"
                fullWidth
                onChange={this.handleNewHolderName}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleCreateOption} color="primary">
                Create Option
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {isCreateRecordSuccess && (
          <Dialog
            open={isCreateRecordSuccess}
            onClose={this.handleGotIt}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Record Saved !!!</DialogTitle>
            <DialogContent>
              <DialogContentText>
                If applicable, includes the usage rights language on the
                statement of work/contract with the rights holder. Provide the
                DRM ID "{newDrmId ? newDrmId : drmId}" to the rights holder to
                tag to the metadata of the asset to ensure rights are properly
                associated in Assethub.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <CopyToClipboard text={`${newDrmId ? newDrmId : drmId}`}>
                <Button
                  data-cy="confirmRecordSaved"
                  onClick={this.handleGotIt}
                  color="primary"
                >
                  Copy DRM ID and Close Window
                </Button>
              </CopyToClipboard>
            </DialogActions>
          </Dialog>
        )}
        {isAddNewBuyer && (
          <Dialog
            open={isAddNewBuyer}
            onClose={this.handleCloseAddNewBuyer}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Create a new Buyer option?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                The option will be available for all users.
              </DialogContentText>
              <TextField
                variant="standard"
                autoFocus
                margin="dense"
                id="name"
                label="Enter Buyer Email"
                type="email"
                fullWidth
                onChange={this.handleNewBuyer}
              />
              {isValidEmail !== true && isValidEmail !== null ? (
                <div style={{ color: 'red', fontSize: '12px', height: '15px' }}>
                  Incorrect Email
                </div>
              ) : (
                <div style={{ visibility: 'hidden', height: '15px' }}>
                  Email Validation
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseAddNewBuyer} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleAddBuyerToDB} color="primary">
                Add Buyer
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    )
  }
}

UsageRightsCreate.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  classes: PropTypes.object,
  headerTitle: PropTypes.string,
  getUsageRightsSummary: PropTypes.func,
  addNewOptionUsageRightsType: PropTypes.func,
  addNewBuyer: PropTypes.func,
  createNewRecordUsageRights: PropTypes.func,
  getUsageRightsOldData: PropTypes.func,
  userEmail: PropTypes.string,
  addNewOptionUsageRightsName: PropTypes.func,
}

UsageRightsCreate.defaultProps = {
  classes: {},
  headerTitle: undefined,
}

const mapStateToProps = (state) => {
  const { layout, projects = {} } = state
  const { headerTitle = '' } = layout
  return {
    projects,
    headerTitle,
    userEmail: selectUserEmail()(state),
    isAdmin: selectIsAdGroupSuperAdmin()(state),
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUsageRightsSummary,
      addNewOptionUsageRightsType,
      createNewRecordUsageRights,
      getUsageRightsOldData,
      addNewOptionUsageRightsName,
      addNewBuyer,
    },
    dispatch,
  )

const MyComponent = (props) => {
  const classes = styles()
  return <UsageRightsCreate {...props} classes={classes} />
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAuth()(withEnv()(withRouter(MyComponent))))
